var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "procedure-detail-wrapper" },
    [
      _c("div", { staticClass: "procedure-detail-header flex-sbc" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "procedureList" })
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.permission("Modify")
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.buttonLoading },
                    on: { click: _vm.switchOrSubmit }
                  },
                  [_vm._v(" " + _vm._s(_vm.saveButton ? "保存" : "编辑") + " ")]
                )
              : _vm._e(),
            _vm.permission("Synchronous") && !_vm.saveButton
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.buttonLoading },
                    on: { click: _vm.synchronousToProcess }
                  },
                  [_vm._v(" 同步至工艺流程 ")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "procedure-detail-body" },
        [
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _vm.saveButton
                  ? _c(
                      "MFormBuilder",
                      {
                        ref: "baseForm",
                        attrs: {
                          "form-list": _vm.baseFormList,
                          "form-data": _vm.baseFormData
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-mode",
                            attrs: { slot: "issueMode" },
                            slot: "issueMode"
                          },
                          [
                            _c("div", { staticClass: "tit" }, [
                              _vm._v("默认下发方式：")
                            ]),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { name: "issueGroup" },
                                on: {
                                  change: function($event) {
                                    return _vm.assingChanges(1)
                                  }
                                },
                                model: {
                                  value: _vm.selectParams.issueMode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.selectParams, "issueMode", $$v)
                                  },
                                  expression: "selectParams.issueMode"
                                }
                              },
                              _vm._l(_vm.issList, function(items) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: items.value,
                                    attrs: { label: items.value }
                                  },
                                  [_vm._v(_vm._s(items.name))]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex-mode",
                            attrs: { slot: "assignMode" },
                            slot: "assignMode"
                          },
                          [
                            _c("div", { staticClass: "tit" }, [
                              _vm._v("默认指派方式：")
                            ]),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { name: "assingGroup" },
                                on: {
                                  change: function($event) {
                                    return _vm.assingChanges(2)
                                  }
                                },
                                model: {
                                  value: _vm.selectParams.assignMode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectParams,
                                      "assignMode",
                                      $$v
                                    )
                                  },
                                  expression: "selectParams.assignMode"
                                }
                              },
                              _vm._l(_vm.assList, function(items) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: items.value,
                                    attrs: { label: items.value }
                                  },
                                  [_vm._v(_vm._s(items.name))]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex-modes",
                            attrs: { slot: "reportSettig" },
                            slot: "reportSettig"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "tit" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        "工单仅一个工序时公差控制报工数量生效，多于一个工序时不生效",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "el-icon-question"
                                    })
                                  ]
                                ),
                                _vm._v(" 工序报工公差设置： ")
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mode-ri" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "ri-select",
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.differenceType,
                                      callback: function($$v) {
                                        _vm.differenceType = $$v
                                      },
                                      expression: "differenceType"
                                    }
                                  },
                                  _vm._l(_vm.procedureDifferenceTypes, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ri-top" },
                                  [
                                    _c("div", { staticClass: "top-tit" }, [
                                      _vm._v("上公差：")
                                    ]),
                                    _c("el-input-number", {
                                      staticClass: "ri-num",
                                      attrs: {
                                        min: 0,
                                        "controls-position": "right",
                                        step: 1,
                                        disabled:
                                          _vm.differenceType ||
                                          _vm.differenceType === 0
                                            ? false
                                            : true
                                      },
                                      model: {
                                        value: _vm.differencePlus,
                                        callback: function($$v) {
                                          _vm.differencePlus = $$v
                                        },
                                        expression: "differencePlus"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ri-top" },
                                  [
                                    _c("div", { staticClass: "top-tit" }, [
                                      _vm._v("下公差：")
                                    ]),
                                    _c("el-input-number", {
                                      staticClass: "ri-num",
                                      attrs: {
                                        min: 0,
                                        max: _vm.maxData,
                                        "controls-position": "right",
                                        step: 1,
                                        disabled:
                                          _vm.differenceType ||
                                          _vm.differenceType === 0
                                            ? false
                                            : true
                                      },
                                      model: {
                                        value: _vm.differenceMinus,
                                        callback: function($$v) {
                                          _vm.differenceMinus = $$v
                                        },
                                        expression: "differenceMinus"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { attrs: { slot: "groupName" }, slot: "groupName" },
                          [
                            _c("CURDSelect", {
                              attrs: {
                                "default-value": _vm.baseFormData.groupId,
                                "api-name": "ProcedureGroup"
                              },
                              on: { change: _vm.selectName }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _c(
                      "el-row",
                      { staticClass: "view-list", attrs: { gutter: 24 } },
                      [
                        _vm._l(_vm.fixData(_vm.columns.bases), function(
                          item,
                          index
                        ) {
                          return _c(
                            "el-col",
                            {
                              key: item.prop,
                              style: { marginBottom: index !== 4 ? "20px" : 0 },
                              attrs: { span: 6 }
                            },
                            [
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(item.label) + "：")
                              ]),
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(_vm.getValue(item)))
                              ])
                            ]
                          )
                        }),
                        _c(
                          "el-col",
                          { style: { margin: "20px 0" }, attrs: { span: 24 } },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-mode" },
                              [
                                _c("div", { staticClass: "tit" }, [
                                  _vm._v("默认下发方式：")
                                ]),
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: {
                                      name: "assingGroups",
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.selectParams.issueMode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectParams,
                                          "issueMode",
                                          $$v
                                        )
                                      },
                                      expression: "selectParams.issueMode"
                                    }
                                  },
                                  _vm._l(_vm.issList, function(items) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: items.value,
                                        attrs: { label: items.value }
                                      },
                                      [_vm._v(_vm._s(items.name))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "flex-mode" },
                            [
                              _c("div", { staticClass: "tit" }, [
                                _vm._v("默认指派方式：")
                              ]),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { name: "assingGroup", disabled: "" },
                                  model: {
                                    value: _vm.selectParams.assignMode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.selectParams,
                                        "assignMode",
                                        $$v
                                      )
                                    },
                                    expression: "selectParams.assignMode"
                                  }
                                },
                                _vm._l(_vm.assList, function(items) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: items.value,
                                      attrs: { label: items.value }
                                    },
                                    [_vm._v(_vm._s(items.name))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c("div", { staticClass: "flex-modes" }, [
                            _c(
                              "div",
                              { staticClass: "tit" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        "工单仅一个工序时公差控制报工数量生效，多于一个工序时不生效",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "el-icon-question"
                                    })
                                  ]
                                ),
                                _vm._v(" 工序报工公差设置： ")
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mode-ri" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "ri-select",
                                    attrs: {
                                      size: "small",
                                      placeholder: "请选择",
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.differenceType,
                                      callback: function($$v) {
                                        _vm.differenceType = $$v
                                      },
                                      expression: "differenceType"
                                    }
                                  },
                                  _vm._l(_vm.procedureDifferenceTypes, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _c("div", { staticClass: "ri-top" }, [
                                  _c("div", { staticClass: "top-tit" }, [
                                    _vm._v("上公差：")
                                  ]),
                                  _c("div", { staticClass: "top-tit" }, [
                                    _vm._v(_vm._s(_vm.differencePlus))
                                  ])
                                ]),
                                _c("div", { staticClass: "ri-top" }, [
                                  _c("div", { staticClass: "top-tit" }, [
                                    _vm._v("下公差：")
                                  ]),
                                  _c("div", { staticClass: "top-tit" }, [
                                    _vm._v(_vm._s(_vm.differenceMinus))
                                  ])
                                ])
                              ],
                              1
                            )
                          ])
                        ])
                      ],
                      2
                    )
              ],
              1
            )
          ]),
          !["mom"].includes(_vm.memberCode)
            ? _c("div", { staticClass: "detail-card" }, [
                _c("div", { staticClass: "card-tit" }, [_vm._v("可执行工位")]),
                _c(
                  "div",
                  { staticClass: "card-bot" },
                  [
                    _vm.saveButton
                      ? _c(
                          "el-button",
                          {
                            staticClass: "b20",
                            attrs: { type: "primary" },
                            on: { click: _vm.addStation }
                          },
                          [_vm._v("添加工位")]
                        )
                      : _vm._e(),
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        "show-page": false,
                        height: 300,
                        columns: _vm.columns.station,
                        data: _vm.stationList
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return _c("div", {}, [
                                _vm._v(" " + _vm._s($index + 1) + " ")
                              ])
                            }
                          },
                          {
                            key: "action",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled: !_vm.saveButton
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.delFrontData(
                                            "stationList",
                                            $index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        1515741046
                      )
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [
              _vm._v("工序指导字段配置")
            ]),
            _c("div", { staticClass: "card-bot" }, [
              _c("div", { staticClass: "pcCard" }, [
                _c("div", { staticClass: "typeTitle" }, [_vm._v("PC端")]),
                _c("div", { staticClass: "card-bot-item" }, [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("生产订单")
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.procedureFieldListPc, function(item, index) {
                      return _c("div", { key: index }, [
                        item.type === 1
                          ? _c(
                              "div",
                              { staticClass: "fieldItem" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: !_vm.saveButton },
                                    model: {
                                      value: item.isShow,
                                      callback: function($$v) {
                                        _vm.$set(item, "isShow", $$v)
                                      },
                                      expression: "item.isShow"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.fieldName))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ]),
                _c("div", { staticClass: "card-bot-item" }, [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("生产计划")
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.procedureFieldListPc, function(item, index) {
                      return _c("div", { key: index }, [
                        item.type === 2
                          ? _c(
                              "div",
                              { staticClass: "fieldItem" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: !_vm.saveButton },
                                    model: {
                                      value: item.isShow,
                                      callback: function($$v) {
                                        _vm.$set(item, "isShow", $$v)
                                      },
                                      expression: "item.isShow"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.fieldName))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ]),
                _c("div", { staticClass: "card-bot-item" }, [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("生产工单")
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.procedureFieldListPc, function(item, index) {
                      return _c("div", { key: index }, [
                        item.type === 3
                          ? _c(
                              "div",
                              { staticClass: "fieldItem" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: !_vm.saveButton },
                                    model: {
                                      value: item.isShow,
                                      callback: function($$v) {
                                        _vm.$set(item, "isShow", $$v)
                                      },
                                      expression: "item.isShow"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.fieldName))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ]),
                _c("div", { staticClass: "card-bot-item" }, [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("自制任务")
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.procedureFieldListPc, function(item, index) {
                      return _c("div", { key: index }, [
                        item.type === 4
                          ? _c(
                              "div",
                              { staticClass: "fieldItem" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: !_vm.saveButton },
                                    model: {
                                      value: item.isShow,
                                      callback: function($$v) {
                                        _vm.$set(item, "isShow", $$v)
                                      },
                                      expression: "item.isShow"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.fieldName))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "pcCard" }, [
                _c("div", { staticClass: "typeTitle" }, [_vm._v("移动端")]),
                _c("div", { staticClass: "card-bot-item" }, [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("生产订单")
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.procedureFieldListApp, function(item, index) {
                      return _c("div", { key: index }, [
                        item.type === 1
                          ? _c(
                              "div",
                              { staticClass: "fieldItem" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: !_vm.saveButton },
                                    model: {
                                      value: item.isShow,
                                      callback: function($$v) {
                                        _vm.$set(item, "isShow", $$v)
                                      },
                                      expression: "item.isShow"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.fieldName))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ]),
                _c("div", { staticClass: "card-bot-item" }, [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("生产计划")
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.procedureFieldListApp, function(item, index) {
                      return _c("div", { key: index }, [
                        item.type === 2
                          ? _c(
                              "div",
                              { staticClass: "fieldItem" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: !_vm.saveButton },
                                    model: {
                                      value: item.isShow,
                                      callback: function($$v) {
                                        _vm.$set(item, "isShow", $$v)
                                      },
                                      expression: "item.isShow"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.fieldName))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ]),
                _c("div", { staticClass: "card-bot-item" }, [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("生产工单")
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.procedureFieldListApp, function(item, index) {
                      return _c("div", { key: index }, [
                        item.type === 3
                          ? _c(
                              "div",
                              { staticClass: "fieldItem" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: !_vm.saveButton },
                                    model: {
                                      value: item.isShow,
                                      callback: function($$v) {
                                        _vm.$set(item, "isShow", $$v)
                                      },
                                      expression: "item.isShow"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.fieldName))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ]),
                _c("div", { staticClass: "card-bot-item" }, [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("自制任务")
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.procedureFieldListApp, function(item, index) {
                      return _c("div", { key: index }, [
                        item.type === 4
                          ? _c(
                              "div",
                              { staticClass: "fieldItem" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: !_vm.saveButton },
                                    model: {
                                      value: item.isShow,
                                      callback: function($$v) {
                                        _vm.$set(item, "isShow", $$v)
                                      },
                                      expression: "item.isShow"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.fieldName))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "pcCard" }, [
                _c("div", { staticClass: "card-bot-item" }, [
                  _c(
                    "div",
                    { staticClass: "item-title" },
                    [
                      _c("span", [_vm._v("自定义")]),
                      _vm.saveButton
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "text", size: "small" },
                              on: { click: _vm.addField }
                            },
                            [_vm._v("新增字段")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    _vm._l(_vm.procedureFieldListCustom, function(item, index) {
                      return _c("div", { key: index }, [
                        item.type === 5
                          ? _c("div", { staticClass: "fieldItem" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: { disabled: !_vm.saveButton },
                                          model: {
                                            value: item.isShow,
                                            callback: function($$v) {
                                              _vm.$set(item, "isShow", $$v)
                                            },
                                            expression: "item.isShow"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.fieldName))]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.saveButton
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: {
                                            type: "text",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteField(
                                                item,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                  _vm.saveButton
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" }
                                      })
                                    : _vm._e(),
                                  _vm.saveButton
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "20px"
                                          },
                                          attrs: {
                                            type: "text",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addField(item, index)
                                            }
                                          }
                                        },
                                        [_vm._v("修改")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("工序操作描述")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入工序操作描述",
                    maxlength: "500",
                    disabled: !_vm.saveButton,
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.operationDesc,
                    callback: function($$v) {
                      _vm.operationDesc = $$v
                    },
                    expression: "operationDesc"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("工序图纸及附件")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _vm._m(0),
                _c("br"),
                _vm.saveButton
                  ? _c(
                      "el-upload",
                      _vm._b(
                        { staticClass: "b20", attrs: { type: "primary" } },
                        "el-upload",
                        _vm.uploadProps,
                        false
                      ),
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function($event) {
                                _vm.uploadKey = "drawingsList"
                              }
                            }
                          },
                          [_vm._v("上传图纸")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("MTable", {
                  ref: "mTable",
                  attrs: {
                    "show-page": false,
                    height: 300,
                    columns: _vm.columns.drawings,
                    data: _vm.drawingsList
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return _c("div", {}, [
                          _vm._v(" " + _vm._s($index + 1) + " ")
                        ])
                      }
                    },
                    {
                      key: "fileName",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _c(
                            "a",
                            {
                              staticStyle: { color: "#607fff" },
                              attrs: {
                                href: row.url,
                                download: row.name,
                                target: "_blank"
                              }
                            },
                            [_vm._v(_vm._s(row.name))]
                          ),
                          _c("img", {
                            staticStyle: {
                              width: "11px",
                              "margin-left": "5px"
                            },
                            attrs: {
                              src: require("@/assets/information/procedure/附件@2x.png")
                            }
                          })
                        ])
                      }
                    },
                    {
                      key: "action",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled: !_vm.saveButton
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.delFrontData(
                                      "drawingsList",
                                      $index
                                    )
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      }
                    }
                  ])
                }),
                _c("div", { staticClass: "bt20 flex-sbc" }, [
                  _c("img", {
                    staticStyle: { width: "18px" },
                    attrs: {
                      src: require("@/assets/information/procedure/其他附件@2x.png")
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "l10",
                      staticStyle: { position: "relative", top: "2px" },
                      on: {
                        click: function($event) {
                          _vm.uploadKey = "filesList"
                        }
                      }
                    },
                    [_vm._v("其他附件")]
                  )
                ]),
                _c("br"),
                _vm.saveButton
                  ? _c(
                      "el-upload",
                      _vm._b(
                        { staticClass: "b20", attrs: { type: "primary" } },
                        "el-upload",
                        _vm.uploadProps,
                        false
                      ),
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function($event) {
                                _vm.uploadKey = "filesList"
                              }
                            }
                          },
                          [_vm._v("上传附件")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("MTable", {
                  ref: "mTable",
                  attrs: {
                    "show-page": false,
                    height: 300,
                    columns: _vm.columns.files,
                    data: _vm.filesList
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return _c("div", {}, [
                          _vm._v(" " + _vm._s($index + 1) + " ")
                        ])
                      }
                    },
                    {
                      key: "fileName",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _c(
                            "a",
                            {
                              staticStyle: { color: "#607fff" },
                              attrs: {
                                href: row.url,
                                download: row.name,
                                target: "_blank"
                              }
                            },
                            [_vm._v(_vm._s(row.name))]
                          ),
                          _c("img", {
                            staticStyle: {
                              width: "11px",
                              "margin-left": "5px"
                            },
                            attrs: {
                              src: require("@/assets/information/procedure/附件@2x.png")
                            }
                          })
                        ])
                      }
                    },
                    {
                      key: "action",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled: !_vm.saveButton
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.delFrontData("filesList", $index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("工序上传参数")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _c("ProcedureParams", {
                  ref: "procedureParams",
                  attrs: {
                    "read-only": !_vm.saveButton,
                    "group-list": _vm.groupList
                  }
                })
              ],
              1
            )
          ]),
          !["bbelc"].includes(_vm.memberCode)
            ? [
                _vm.procedureType === 1
                  ? _c("div", { staticClass: "detail-card" }, [
                      _c("div", { staticClass: "card-tit" }, [
                        _vm._v("外协工序时间设置")
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-bot" },
                        [
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "10px" } },
                            [_vm._v("外协默认周期：")]
                          ),
                          _c("el-input-number", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              size: "small",
                              "controls-position": "right",
                              min: 0,
                              step: 1,
                              disabled: !_vm.saveButton
                            },
                            model: {
                              value: _vm.outsourceTime,
                              callback: function($$v) {
                                _vm.outsourceTime = $$v
                              },
                              expression: "outsourceTime"
                            }
                          }),
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "10px" } },
                            [_vm._v("天")]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                !["bbelc", "jinshi"].includes(_vm.memberCode)
                  ? _c("div", { staticClass: "detail-card" }, [
                      _c("div", { staticClass: "card-tit" }, [
                        _vm._v("工序质检设置")
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-bot" },
                        [
                          _c(
                            "el-tabs",
                            {
                              on: { "tab-click": _vm.tabChange },
                              model: {
                                value: _vm.activeName,
                                callback: function($$v) {
                                  _vm.activeName = $$v
                                },
                                expression: "activeName"
                              }
                            },
                            [
                              _vm._l(_vm.tabs, function(item, index) {
                                return [
                                  _vm.procedureType !== 1 ||
                                  (_vm.procedureType === 1 &&
                                    item.key !== "inspectionProjectGroupFirst")
                                    ? _c(
                                        "el-tab-pane",
                                        {
                                          key: index,
                                          attrs: {
                                            label: item.label,
                                            name: item.key
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "10px"
                                                  }
                                                },
                                                [_vm._v("是否需要质检：")]
                                              ),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  attrs: {
                                                    name: item.key,
                                                    disabled: !_vm.saveButton
                                                  },
                                                  on: {
                                                    change: function(val) {
                                                      _vm.isNeedQualityInspectionChange(
                                                        val,
                                                        item.key
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.qualityInspectionPlan[
                                                        item.key
                                                      ].isNeedInspect,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm
                                                          .qualityInspectionPlan[
                                                          item.key
                                                        ],
                                                        "isNeedInspect",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "qualityInspectionPlan[item.key].isNeedInspect"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("是")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 0 } },
                                                    [_vm._v("否")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "10px"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "10px"
                                                  }
                                                },
                                                [_vm._v("质检完成才能出站：")]
                                              ),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  attrs: {
                                                    name: item.key,
                                                    disabled: !_vm.saveButton
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.qualityInspectionPlan[
                                                        item.key
                                                      ].isMustCheck,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm
                                                          .qualityInspectionPlan[
                                                          item.key
                                                        ],
                                                        "isMustCheck",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "qualityInspectionPlan[item.key].isMustCheck"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("是")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 0 } },
                                                    [_vm._v("否")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.qualityInspectionPlan[item.key]
                                            .isNeedInspect === 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "padding-top": "15px"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "10px"
                                                      }
                                                    },
                                                    [_vm._v("检验类型：")]
                                                  ),
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        name: item.key,
                                                        disabled: !_vm.saveButton
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .qualityInspectionPlan[
                                                            item.key
                                                          ].isFullInspection,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm
                                                              .qualityInspectionPlan[
                                                              item.key
                                                            ],
                                                            "isFullInspection",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "qualityInspectionPlan[item.key].isFullInspection"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-option",
                                                        {
                                                          attrs: {
                                                            label: "全检",
                                                            value: 1
                                                          }
                                                        },
                                                        [_vm._v("全检")]
                                                      ),
                                                      _c(
                                                        "el-option",
                                                        {
                                                          attrs: {
                                                            label: "抽检",
                                                            value: 0
                                                          }
                                                        },
                                                        [_vm._v("抽检")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.qualityInspectionPlan[item.key]
                                            .isNeedInspect === 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "15px 0"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "10px"
                                                      }
                                                    },
                                                    [_vm._v("质检方案：")]
                                                  ),
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "200px"
                                                      },
                                                      attrs: {
                                                        size: "small",
                                                        disabled: !_vm.saveButton,
                                                        filterable: "",
                                                        placeholder:
                                                          "请选择质检方案"
                                                      },
                                                      on: {
                                                        change: function(val) {
                                                          return _vm.qualityInspectionChange(
                                                            val,
                                                            item.key
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .qualityInspectionPlan[
                                                            item.key
                                                          ].inspectId,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm
                                                              .qualityInspectionPlan[
                                                              item.key
                                                            ],
                                                            "inspectId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "qualityInspectionPlan[item.key].inspectId"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.formOptions
                                                        .inspectionPlanList,
                                                      function(is) {
                                                        return _c("el-option", {
                                                          key: is.id,
                                                          attrs: {
                                                            label: is.name,
                                                            value: is.id
                                                          }
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.qualityInspectionPlan[item.key]
                                            .isNeedInspect === 1 &&
                                          _vm.qualityInspectionPlan[item.key]
                                            .inspectId
                                            ? _c("quality-inspection-project", {
                                                ref: item.ref,
                                                refInFor: true,
                                                attrs: {
                                                  "inspection-entry-map":
                                                    _vm.qualityInspectionPlan[
                                                      item.key
                                                    ].inspectEntryMap,
                                                  attachments:
                                                    _vm.qualityInspectionPlan[
                                                      item.key
                                                    ].inspectAttachments,
                                                  "user-list":
                                                    _vm.qualityInspectionPlan[
                                                      item.key
                                                    ].inspectorIdList,
                                                  "read-only": !_vm.saveButton,
                                                  baseFormData:
                                                    _vm.qualityInspectionPlan[
                                                      item.key
                                                    ]
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "MDialog",
        {
          attrs: {
            width: "400px",
            title: _vm.isAddField ? "新增自定义字段" : "编辑自定义字段"
          },
          on: { onOk: _vm.submitFieldForm },
          model: {
            value: _vm.fieldVisible,
            callback: function($$v) {
              _vm.fieldVisible = $$v
            },
            expression: "fieldVisible"
          }
        },
        [
          _c("el-input", {
            staticStyle: { "padding-right": "15px" },
            attrs: { placeholder: "请输入自定义字段" },
            model: {
              value: _vm.addOrModifyField.fieldName,
              callback: function($$v) {
                _vm.$set(_vm.addOrModifyField, "fieldName", $$v)
              },
              expression: "addOrModifyField.fieldName"
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "添加工位" },
          on: { onOk: _vm.submitStationForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "选择同步模式" },
          on: { onOk: _vm.submitProcessForm },
          model: {
            value: _vm.processVisible,
            callback: function($$v) {
              _vm.processVisible = $$v
            },
            expression: "processVisible"
          }
        },
        [
          _c(
            "el-radio-group",
            {
              staticStyle: { display: "block", "margin-bottom": "10px" },
              model: {
                value: _vm.tongRadio,
                callback: function($$v) {
                  _vm.tongRadio = $$v
                },
                expression: "tongRadio"
              }
            },
            [
              _c(
                "el-radio",
                {
                  staticStyle: { display: "block", "margin-bottom": "10px" },
                  attrs: { label: 1, value: "1" }
                },
                [_vm._v("同步至所有相关工艺路线")]
              ),
              _c("el-radio", { attrs: { label: 2, value: "2" } }, [
                _vm._v("同步到指定工艺路线")
              ])
            ],
            1
          ),
          _c("el-cascader-multi", {
            staticStyle: {
              "margin-right": "20px",
              "margin-left": "25px",
              width: "80%"
            },
            attrs: {
              placeholder: "工艺-版本号",
              "value-key": "id",
              "label-key": "version",
              "children-key": "versionList",
              data: _vm.onlyProcessList,
              "only-last": true,
              disabled: _vm.tongRadio === 1
            },
            model: {
              value: _vm.onlyProcess,
              callback: function($$v) {
                _vm.onlyProcess = $$v
              },
              expression: "onlyProcess"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: { src: require("@/assets/information/procedure/图纸@2x.png") }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("图纸")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }