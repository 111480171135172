import { procedureType, status } from '@/config/options.config'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const base = [
  {
    prop: 'code',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', colSpan: 6, attrs: { maxlength: 60, "show-word-limit": true}}
  },
  {
    prop: 'name',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', colSpan: 6, attrs: { maxlength: 60, "show-word-limit": true} }
  },
  {
    prop: 'procedureClassId',
    label: '工序分类',
    sortable: false,
    hiddenSearch: true,
    form: {
      colSpan: 6,
      tagName: 'el-cascader',
      key: 'procedureClassId',
      required: false,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.procedureClassChange(val) }
      }
    }
  },
  {
    prop: 'type',
    label: '工序类别',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      colSpan: 6,
      props: {
        disabled: ['bbelc'].includes(sessionStorage.getItem('memberCode'))
      },
      options: procedureType,
      defaultValue: 0,
      on: {
        change: (val) => { functionList.procedureTypeChange(val) }
      }
    }
  },
  {
    prop: 'isEnabled',
    label: '工序状态',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      colSpan: 6,
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'groupName',
    label: '所属工序段',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'div',
      colSpan: 6,
      required: false,
      customSlots: ['groupName']
    }
  },
  {
    prop: 'workCenterName',
    label: '工作中心',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-select', colSpan: 6, required: false, key: 'workCenterId', options: [] },
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      colSpan: 6,
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    label: '',
    sortable: false,
    hiddenSearch: true,
    form: {
      colSpan: 24,
      index: 5,
      key: 'issueMode',
      tagName: 'div',
      customSlots: ['issueMode']
    }
  },
  {
    label: '',
    sortable: false,
    hiddenSearch: true,
    form: {
      colSpan: 24,
      index: 6,
      key: 'assignMode',
      tagName: 'div',
      customSlots: ['assignMode']
    }
  },
  {
    label: '',
    sortable: false,
    hiddenSearch: true,
    form: {
      colSpan: 24,
      index: 6,
      required: false,
      key: 'differenceType',
      tagName: 'div',
      customSlots: ['reportSettig']
    }
  }
]

export const station = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工位名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]
export const fileAndDrawings = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '名称',
    slotName: 'fileName',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]

