var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unit-wrap" }, [
    _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "space-between" } },
      [
        _c(
          "div",
          { style: { width: _vm.type === 0 ? "100%" : "50%" } },
          [
            _c("FunctionHeader", {
              attrs: {
                tags: _vm.functionHeader.tags,
                "search-title": _vm.searchTitle,
                "columns-key": _vm.columnsKey,
                "import-name": _vm.functionHeader.importName,
                "export-name": _vm.functionHeader.exportName,
                "export-params": _vm.functionHeader.exportParams
              },
              on: {
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            }),
            _c(
              "MTable",
              {
                ref: "mTable",
                attrs: {
                  columns: _vm.getColumns,
                  height: _vm.heightAll,
                  "columns-key": _vm.columnsKey,
                  "set-data-method": _vm.getTableData,
                  "row-style": _vm.getRowStyle
                },
                on: { "row-click": _vm.handleRowClick },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "materialsTypeName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [_vm._v(_vm._s(row.materialsTypeName))])
                      ])
                    }
                  },
                  {
                    key: "missMaterialsRate",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [_vm._v(_vm._s(row.missMaterialsRate) + "%")])
                      ])
                    }
                  }
                ])
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "headerSlot" }, slot: "headerSlot" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          color: "#000"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-right": "15px",
                              cursor: "pointer"
                            },
                            attrs: { title: "物料需求" },
                            on: {
                              click: function($event) {
                                return _vm.viewDetail(1)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-d-arrow-right" })]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-right": "15px",
                              cursor: "pointer"
                            },
                            attrs: { title: "物料供应" },
                            on: {
                              click: function($event) {
                                return _vm.viewDetail(2)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-d-arrow-left" })]
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ],
          1
        ),
        _vm.type === 1
          ? _c(
              "div",
              {
                staticStyle: { cursor: "pointer", margin: "53px 8px 0" },
                attrs: { title: "物料总览" }
              },
              [
                _c("i", {
                  staticClass: "el-icon-d-arrow-left",
                  on: {
                    click: function($event) {
                      return _vm.viewDetail(0)
                    }
                  }
                })
              ]
            )
          : _vm._e(),
        _vm.type === 1
          ? _c(
              "div",
              { staticStyle: { width: "48%", "margin-top": "53px" } },
              [
                _c("MTable", {
                  ref: "mTables",
                  attrs: {
                    columns: _vm.columnsRequirement,
                    "show-page": false,
                    "columns-setting": false,
                    height: _vm.height,
                    "columns-key": _vm.columnsKey,
                    data: _vm.requireList,
                    "is-tree-data": true,
                    "default-expand-all": _vm.isExpand
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "missMaterialsRate",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c("div", [
                              _vm._v(_vm._s(row.missMaterialsRate) + "%")
                            ])
                          ])
                        }
                      }
                    ],
                    null,
                    false,
                    14497025
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.type === 2
          ? _c(
              "div",
              {
                staticStyle: { margin: "53px 8px 0", cursor: "pointer" },
                attrs: { title: "物料总览" }
              },
              [
                _c("i", {
                  staticClass: "el-icon-d-arrow-right",
                  on: {
                    click: function($event) {
                      return _vm.viewDetail(0)
                    }
                  }
                })
              ]
            )
          : _vm._e(),
        _vm.type === 2
          ? _c(
              "div",
              { staticStyle: { width: "48%", "margin-top": "53px" } },
              [
                _c("MTable", {
                  ref: "mTabless",
                  attrs: {
                    "is-tree-data": true,
                    "tree-props": { children: "children" },
                    "show-page": false,
                    columns: _vm.columnsSupply,
                    data: _vm.supplyList,
                    "columns-setting": false,
                    "default-expand-all": "",
                    "row-style": _vm.getRowDelayStyle
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      }
                    ],
                    null,
                    false,
                    442309118
                  )
                })
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }