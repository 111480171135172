import { base,getFunctionList} from '@/views/information/production/process/detail/columns'
import { baseFormList, versionFormList } from '@/views/information/production/process/detail/form-list'
import { getUUid } from '@/utils'
import processApi from '@/api/information/production/process'
import { Encrypt } from '@/utils/sercet'
import curdApi from '@/api/CURDSelect'

export default {
  data() {
    return {
      processFlowClassId: '',
      processFlowClassName: '',
      params: {},
      buttonLoading: false,
      baseFormData: { processFlowVersion: {}, processFlowClassName: '', processFiles: [] },
      saveButton: true,
      columns: {
        base
      },
      baseFormList,
      formOptions: {
        areaOptions: [],
        // erpWarehouseOptions: []
      },
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        view: '保存成功',
        viewO: '工艺详情',
        replaceNameO: '工艺详情',
        delVersionO: '工艺详情',
        setMainO: '工艺详情',
        addO: '新增工艺',
        modifyO: '修改工艺',
        copyO: '复制工艺',
        copyVersionO: '复制版本',
        modifyVersionO: '修改版本',
        copyVersion: '版本复制成功',
        replaceName: '重命名',
        modifyVersion: '版本修改成功'
      },
      defaultSearch: {
        id: getUUid(),
        fieldName: 'isEnabled',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      },
      versionList: [],
      currentVersionId: null,
      currVersionName: null,
      processFlowVersion: {},
      versionFormList,
      versionFormData: {},
      versionVisible: false,
      formatReturnData: {},
      materialParams: { status: 1, page: 1, size: 20, search: '',materialsId:'' },
      addType:'normal'
    }
  },
  computed: {
    needErp() {
      return ['reagold', 'bbelc'].includes(sessionStorage.getItem('memberCode'))
    }
  },
  watch: {
    'materialParams.search'(val) {
      this.baseFormList[8].children[0].options.length = 0
      this.materialParams.page = 1
      this.getFinishedMaterials(this.materialParams)
    }
  },
  created() {
    this.baseFormList[8].children[0].options.length = 0
  },
  beforeMount() {
    getFunctionList({
      searchChange: this.searchChange
    })
  },
  mounted() {
    setTimeout(async() => {
      this.OriginData = await this.$refs.processFlow?.formatReturnData(false, true) || {}
    }, 1000)
  },
  methods: {
    searchChange(val){
      if(!val) {
        this.materialParams.search = ''
        this.materialParams.materialsId = ''
        this.baseFormList[8].children[0].options = []
        this.getFinishedMaterials(this.materialParams)
      }
    },
    addDetails(val) {
      const { id, version, isMain, ...data } = val
      this.processFlowVersion = Object.assign({}, this.processFlowVersion, { ...data })
    },
    // 获取路线类型
    async getTypes() {
      const res = await curdApi.getProcessTypeList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        await this.$refs.baseForm.setForm({
          processFlowClassId: res.length > 0 ? res[0].id : '',
          processFlowClassName: res.length > 0 ? res[0].name : ''
        })
      }
    },
    async createProcessCode() {
      // if ( this.params.type === 'copy'&& sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await processApi.createCode({
        count: 1
      })
      if (res) {
        this.$refs.baseForm.setForm({
          code: res[0]
        })
      }
    },
    async selectName(item) {
      await this.$refs.baseForm.setForm({
        processFlowClassId: item.id,
        processFlowClassName: item.name
      })
      this.$refs.baseForm.formRefs().validateField('processFlowClassName')
    },
    async selectFinishedMaterial(item) {
      await this.$refs.baseForm.setForm({
        materialsId: item.id
      })
      this.$refs.baseForm.formRefs().validateField('materialsId')
    },
    // 获取产线
    async getAreaList() {
      const res = await processApi.getAreaList({
        searchVal: Encrypt(JSON.stringify(
          [this.defaultSearch, {
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 2,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.formOptions.areaOptions = res
        this.baseFormList[2].children[0].options = res
      }
    },
    // 获取ERP仓库
    // async getErpWarehouseList() {
    //   const res = await processApi.getErpWarehouseList()
    //   if (res) {
    //     this.formOptions.erpWarehouseOptions = res
    //     this.baseFormList[this.baseFormList.length - 2].children[0].options = res
    //   }
    // },
    // 更具id获取工艺详情
    async getProcessDetailById(id) {
      const res = await processApi.getProcessDetail({ id })
      if (res) {
        res.processFiles = res.processFiles || []
        this.baseFormData = res
        this.baseFormData.areaIdList = res.areaIdList[0] || ""
        this.materialParams = { status: 1, page: 1, size: 20, search: '',materialsId: res.materialsId}
        this.getFinishedMaterials(this.materialParams)
        this.versionList = res.versionList
        const currentVersion = res.versionList.find(item => item.id === this.currentVersionId)
        if (currentVersion) {
          this.processFlowVersion = this._.cloneDeep(currentVersion)
        } else {
          this.processFlowVersion = res.processFlowVersion
          this.currentVersionId = res.processFlowVersion.id
        }
      }
      // if (this.params.type === 'copy') await this.createProcessCode()
    },
    searchInspectTable(val){
      // this.getProcessDetailById(346)
    },
    // 获取基本信息值
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && !['materialsFullName', 'areas'].includes(row.prop)) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    viewVersion(version) {
      this.processFlowVersion = version
      this.currentVersionId = version.id
      this.currVersionName = version.version
    },
    versionMore(command) {
      this.params.type = command
      const functions = {
        modifyVersion: () => { this.modifyOrCopyVersion(command) },
        setMain: () => { this.setMainVersion(command) },
        copyVersion: () => { this.modifyOrCopyVersion(command) },
        delVersion: () => { this.delVersion() },
        replaceName: () => { this.replaceVersionName() }
      }
      functions[command]()
    },
    // 设为主版本
    async setMainVersion() {
      this.$confirm('您确定要将此版本设置为主版本吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await processApi.setMainVersion(this.processFlowVersion.id)
        if (res) {
          this.$message.success('设置成功')
          await this.getProcessDetailById(this.params.id)
        }
      })
    },
    // 复制版本
    modifyOrCopyVersion(type) {
      if (this.saveButton) {
        this.submitForm()
      } else {
        this.params.type = type
        this.saveButton = true
      }
    },
    // 删除版本
    delVersion() {
      this.$confirm('您确定要删除此版本吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await processApi.delProcessVersion(this.processFlowVersion.id)
        if (res) {
          this.$message.success('删除成功')
          await this.getProcessDetailById(this.params.id)
        }
      })
    },
    // 更改版本名称
    replaceVersionName() {
      this.versionFormList[1].hidden = true
      this.versionFormData = {
        name: this.processFlowVersion.version
      }
      this.versionVisible = true
    },
    // 点击编辑/保存按钮
    switchOrSubmit() {
      if (this.saveButton) {
        this.submitForm()
      } else {
        this.params.type = 'modify'
        this.saveButton = true
      }
    },
    // 保存
    submitForm() {
      this.buttonLoading = true
      this.$refs.baseForm.formRefs().validate(async(valid) => {
        if (valid) {
          this.formatReturnData = await this.$refs.processFlow.formatReturnData(false, true)
          if (this.formatReturnData) {
            // 新增/复制需要确认版本名
            const { form } = this.$refs.baseForm
            if (['add', 'copy'].indexOf(this.params.type) !== -1) {
              this.versionFormData = {
                name: '版本1',
                isMain: 1
              }
              await this.modifyOrAddProcess()
            }
            if (['copyVersion'].indexOf(this.params.type) !== -1) {
              const res = await processApi.getVersionName(form.id)
              if (res) {
                this.versionFormList[1].hidden = this.params.type !== 'copyVersion'
                this.versionFormData = {
                  name: res,
                  isMain: 0
                }
                this.versionVisible = true
              }
              this.buttonLoading = false
            }
            console.log(this.params.type, '---')
            if (['modify', 'modifyVersion'].indexOf(this.params.type) !== -1) {
              this.versionFormData = {
                name: form.processFlowVersion.version,
                isMain: form.processFlowVersion.isMain
              }
              await this.modifyOrAddProcess()
            }
          } else {
            this.buttonLoading = false
          }
        } else {
          this.buttonLoading = false
        }
      })
    },
    // 修改或新增工艺
    async modifyOrAddProcess() {
      const { form } = this.$refs.baseForm
      let res
      const bodyData = {
        ...form,
        ...this.formatReturnData
      }
      console.log('工艺路线创建类型==', this.params.type, bodyData.procedureList)
      try {
        if (['add', 'copy'].indexOf(this.params.type) !== -1) {
          const data = this.createSetData(bodyData)
          data.processFlow.processFiles = this.$refs.ProcessFile.baseList
          res = await processApi.addProcess(data)
        }
        if (['copyVersion'].indexOf(this.params.type) !== -1) {
          res = await processApi.addProcessVersion(this.createSetVersionData(bodyData))
        }
        if (['modify', 'modifyVersion'].indexOf(this.params.type) !== -1) {
          // const tip = '修改工艺路线后将释放原本已占用的库存，是否确认？'
          // const allow = await this.$confirm(tip, '修改工艺路线', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // })
          // allow && (res = await processApi.modifyProcess(this.createResetData(bodyData)))
          res = await processApi.modifyProcess(this.createResetData(bodyData))
        }
      } catch (error) {
      }
      this.buttonLoading = false
      if (res) {
        this.saveButton = false
        this.versionVisible = false
        this.$message.success(this.messages[this.params.type])
        if(this.addType === 'normal'){
          if (['add', 'copy'].indexOf(this.params.type) !== -1) {
            this.$router.push({ query: { type: 'view', id: res }})
            this.params = { type: 'view', id: res }
          }
          await this.getProcessDetailById(this.params.id)
        }else{
          this.$router.push({ name: 'changeToMbom', query: { id: this.$route.query.bomId,processId:res }})
        }
        if (['modifyVersion'].indexOf(this.params.type) !== -1 && res.length > 0) {
          this.$confirm('修改工艺路线后将同步工单，是否确认？', '同步工单', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async() => {
            await processApi.synchronizedWorkOrder({productionPlanNumbers:res })
          })
        }
      }
    },
    async submitVersionForm(callback) {
      const { form } = this.$refs.versionForm
      if (this.params.type === 'replaceName') {
        const res = await processApi.replaceProcessVersionName({
          processFlowVersionId: this.processFlowVersion.id,
          newName: form.name
        })
        if (res) {
          this.$message.success('重命名成功')
          await this.getProcessDetailById(this.params.id)
          this.versionVisible = false
        }
        callback()
      }
      if (this.params.type === 'copyVersion') {
        await this.modifyOrAddProcess()
        callback()
      }
    },
    createSetData(raw) {
      let currentErp = {}
      if (this.needErp && raw.erpWarehouseId && raw.erpWarehouseId !== -1) {
        currentErp = this.formOptions.erpWarehouseOptions.find(item => item.id === raw.erpWarehouseId)
      }
      // 自定义节点编号，便于填写excel
      // raw.originFlow.forEach((item, idx) => {
      //   // item.attrs.label.text = `${item.attrs.label.text}( ${idx + 1} )`
      //   const labelId = idx + 1
      //   item.attrs.label.text =this.matchVal(item.attrs.label.text,labelId)||labelId
      // });
      // raw.procedureList.forEach((item, idx) => {
      //   item.nodeLabelText = idx + 1
      // });
      return {
        processFlow: {
          code: raw.code,
          name: raw.name,
          isEnabled: raw.isEnabled,
          isPartialOutbound: raw.isPartialOutbound,
          erpWarehouseId: -1,
          // erpWarehouseCode: currentErp.code || '',
          // erpWarehouseName: currentErp.name || '',
          processFlowVersion: {
            id: this.processFlowVersion.id,
            version: this.versionFormData.name,
            procedureDetails: JSON.stringify(raw.formatFlow),
            details: JSON.stringify(raw.originFlow),
            isMain: this.versionFormData.isMain,
            materialsBomId:this.$route.query.bomId,
          },
          remark: raw.remark,
          type: raw.type,
          processFlowClassId: raw.processFlowClassId,
          processFlowClassName: raw.processFlowClassName,
          materialsId: raw.materialsId,
          overProductionType: raw.overProductionType
        },
        procedureList: raw.procedureList,
        areaIdList: raw.areaIdList ? (Array.isArray(raw.areaIdList) ? raw.areaIdList : [raw.areaIdList]) : [],
      }
    },
    createSetVersionData(raw) {
      let versionFormData = this.versionFormData
      if (this.$refs.versionForm) {
        versionFormData = this.$refs.versionForm.form
      }
      // 自定义节点编号，便于填写excel
      // raw.originFlow.forEach((item, idx) => {
      //   item.attrs.label.text = `${item.attrs.label.text}( ${idx + 1} )`
      // });
      // raw.procedureList.forEach((item, idx) => {
      //   item.nodeLabelText = idx + 1
      // });
      return {
        processFlowId: raw.id,
        version: versionFormData.name,
        procedureDetails: JSON.stringify(raw.formatFlow),
        details: JSON.stringify(raw.originFlow),
        isMain: versionFormData.isMain,
        remark: raw.remark,
        procedureList: raw.procedureList
      }
    },
    createResetData(raw) {
      let currentErp = {}
      if (this.needErp && raw.erpWarehouseId && raw.erpWarehouseId !== -1) {
        currentErp = this.formOptions.erpWarehouseOptions.find(item => item.id === raw.erpWarehouseId)
      }
      // 自定义节点编号，便于填写excel
      // raw.originFlow.forEach((item, idx) => {
      //   item.attrs.label.text = `${item.attrs.label.text}( ${idx + 1} )`
      // });
      // raw.procedureList.forEach((item, idx) => {
      //   item.nodeLabelText = idx + 1
      // });
      return {
        id: raw.id,
        code: raw.code,
        name: raw.name,
        areaIdList: raw.areaIdList ? (Array.isArray(raw.areaIdList) ? raw.areaIdList : [raw.areaIdList]) : [],
        isEnabled: raw.isEnabled,
        isPartialOutbound: raw.isPartialOutbound,
        erpWarehouseId: -1,
        // erpWarehouseCode: currentErp.code || '',
        // erpWarehouseName: currentErp.name || '',
        remark: raw.remark,
        type: raw.type,
        processFlowClassId: raw.processFlowClassId,
        processFlowClassName: raw.processFlowClassName,
        materialsId: raw.materialsId,
        overProductionType: raw.overProductionType,
        updateProcessFlowVersionParamBean: {
          id: this.processFlowVersion.id,
          processFlowId: raw.id,
          version: this.processFlowVersion.version,
          procedureDetails: JSON.stringify(raw.formatFlow),
          details: JSON.stringify(raw.originFlow),
          isMain: this.processFlowVersion.isMain,
          procedureList: raw.procedureList
        }
      }
    },
    async back() {
      const changeData = await this.$refs.processFlow?.formatReturnData(false, true) || {}
      if (!this._.isEqual(changeData.procedureList, this.OriginData.procedureList) && this.saveButton) {
        this.$confirm('当前页有内容未保存，是否离开?', '提示', {
          confirmButtonText: '继续编辑',
          cancelButtonText: '直接离开',
          type: 'warning'
        }).then(async() => {
          // this.modifyOrCopyVersion('modify')
        }).catch(() => {
          if (!this.saveButton || ['add', 'copy'].indexOf(this.params.type) !== -1) {
            this.$router.push({ name: 'processList' })
          } else {
            this.saveButton = false
            this.params.type = 'view'
          }
        })
      } else {
        if (!this.saveButton || ['add', 'copy'].indexOf(this.params.type) !== -1) {
          this.$router.push({ name: 'processList' })
        } else {
          this.saveButton = false
          this.params.type = 'view'
        }
      }
    },
    // 成品物料下拉触底分页加载
    finishedMaterialLoadMore() {
      if (this.baseFormList[8].children[0].options.length === this.materialsTotal) return
      if (this.baseFormList[8].children[0].options.length > this.materialsTotal) {
        this.baseFormList[8].children[0].options = new Set(this.baseFormList[8].children[0].options)
        return this.baseFormList[8].children[0].options
      }
      this.materialParams.page++
      this.getFinishedMaterials(this.materialParams)
    },
    // 获取成品物料
    async getFinishedMaterials(params) {
      const res = await processApi.getFinishedMaterials(params)
      if (res) {
        this.materialsTotal = res.total
        this.baseFormList[8].children[0].options.push(...res.records || [])
      }
    },

    // 匹配字符串最后一个括号内的内容
    matchVal(str, newVal) {
      const match = str.match(/\(([^)]+)\)[^(]*$/)
      if (match) {
        const content = match[1]
        const replacedStr = str.replace(`(${content})`, newVal)
        return replacedStr
      } else {
        return false
      }
    }
  }
}
