var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MDialog",
    {
      attrs: {
        title: _vm.title,
        width: _vm.width - 320 + "px",
        showFullScreen: true
      },
      on: { onOk: _vm.submitForm, fullScreen: _vm.fullScreenChange },
      model: {
        value: _vm.modalShow,
        callback: function($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow"
      }
    },
    [
      _c(
        "div",
        { staticClass: "m-table" },
        [
          _c(
            "FunctionHeader",
            {
              staticClass: "materials-head",
              attrs: {
                "search-title": "请输入物料编号/名称/型号",
                tags: _vm.functionHeader.tags,
                "columns-setting": false,
                isDialog: true
              },
              on: {
                search: function($event) {
                  return _vm.getReportItemLog()
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { clearable: "", placeholder: "选择工序" },
                  on: { change: _vm.selectProcess },
                  model: {
                    value: _vm.processId,
                    callback: function($$v) {
                      _vm.processId = $$v
                    },
                    expression: "processId"
                  }
                },
                _vm._l(_vm.processList, function(item) {
                  return _c("el-option", {
                    key: item.procedureId,
                    attrs: {
                      label: item.procedureFullName,
                      value: item.procedureId
                    }
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    clearable: "",
                    placeholder: "选择模组",
                    multiple: ""
                  },
                  on: { change: _vm.selectModule },
                  model: {
                    value: _vm.moduleId,
                    callback: function($$v) {
                      _vm.moduleId = $$v
                    },
                    expression: "moduleId"
                  }
                },
                _vm._l(_vm.moduleList, function(item) {
                  return _c("el-option", {
                    key: item.materialsId,
                    attrs: {
                      label: item.materialsFullName,
                      value: item.materialsId
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "table",
              attrs: {
                data: _vm.dataProcess,
                height: _vm.tableHeight,
                "default-expand-all": _vm.isExpand
              },
              on: { "expand-change": _vm.onExpand }
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "el-table",
                          {
                            ref:
                              "expandTable" + props.row.procedureLocationCode,
                            staticClass: "ellipsis-table",
                            attrs: {
                              data: props.row.children,
                              "row-key": "id"
                            },
                            on: {
                              "selection-change": function(data) {
                                return _vm.handleSelectionChange(
                                  data,
                                  props.$index
                                )
                              }
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                "reserve-selection": true,
                                selectable: _vm.selectable
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "index",
                                label: "序号",
                                width: "60"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(scope.$index + 1) + " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "materialsType",
                                label: "物料类型"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.materialsType) +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "materialsCode",
                                label: "物料编号"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "materialsName",
                                label: "物料名称",
                                width: "200"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "specifications",
                                label: "物料规格"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "isKeyMaterials",
                                label: "是否关键物料"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.isKeyMaterials
                                                ? "是"
                                                : "否"
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "mainUnitName", label: "主单位" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "requirementQuantity",
                                label: "需求数量"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "receivedQuantity",
                                label: "已领数量"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "notReceiveQuantity",
                                label: "未领数量"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "appliedUnissuedQuantity",
                                label: "申请未发数量"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "requireDate", label: "需求日期" }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "procedureName", label: "工序名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "procedureLocationCode", label: "工序定位码" }
              }),
              _c("el-table-column", {
                attrs: { prop: "requirementQuantity", label: "需求数量" }
              }),
              _c("el-table-column", {
                attrs: { prop: "receivedQuantity", label: "已领数量" }
              }),
              _c("el-table-column", {
                attrs: { prop: "notReceiveQuantity", label: "未领数量" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "keyMaterialsRequirementQuantity",
                  label: "关键料数量"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "procedureCompleteRate", label: "工序齐套率" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.procedureCompleteRate) + "% "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "notReceiveCompleteRate", label: "未领齐套率" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.notReceiveCompleteRate) + "% "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "keyMaterialsCompleteRate",
                  label: "关键料齐套率"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.keyMaterialsCompleteRate) +
                            "% "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page.currentPage,
              "page-sizes": _vm.page.pageSizes,
              "page-size": _vm.page.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.page.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }