
import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: true,
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'drawing',
    label: '物料图片',
    slotName: 'drawing',
    sortable: false,
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'code',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    minWidth: 150,
    form: { index: 0, tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      required: false,
      tagName: 'el-cascader',
      key: 'materialsClassId',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        filterable: true
      }
    }
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      tagName: 'el-select',
      key: 'materialsTypeId',
      required: false
    }
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 5,
      tagName: 'el-select',
      key: 'materialsAttributeId',
      required: false
    }
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    form: { index: 2, tagName: 'el-input', required: false }
  },
  {
    prop: 'mainUnitName',
    label: '主单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '物料状态',
    sortable: false,
    slotName: 'isEnabled',
    filters: formatColumns(status),
    form: {
      index: 6,
      tagName: 'el-select',
      options: status,
      required: false,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index: 7, tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 115
  }
]

export const unitColumns = [
  {
    label: '类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type'
  },
  {
    prop: 'name',
    label: '单位名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name',
    form: {
      index: 0,
      key: 'id',
      tagName: 'el-select',
      on: {
        change: (val) => { functionList.unitChange(val) }
      }
    }
  },
  {
    prop: 'calc',
    label: '换算系数',
    sortable: false,
    hiddenSearch: true,
    slotName: 'calc'
  },
  {
    prop: 'isMappingEnable',
    label: '单位状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isMappingEnable',
    form: {
      index: 1,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index: 2, tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 150
  }
]

export const packageColumns = [
  {
    prop: 'name',
    label: '包装容器名称',
    sortable: false,
    hiddenSearch: true,
    form: {
      key: 'id',
      tagName: 'div',
      customSlots: ['packageName']
      // on: {
      //   change: (val) => { functionList.packageChange(val) }
      // }
    }
  },
  {
    prop: 'specification',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { disabled: true }}
  },
  {
    prop: 'containerUnitName',
    label: '包装单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'calc',
    label: '换算系数',
    sortable: false,
    hiddenSearch: true,
    slotName: 'calc'
  },
  {
    prop: 'isMappingEnable',
    label: '包装容器状态',
    sortable: false,
    slotName: 'isMappingEnable',
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 150
  }
]
export const columnsAll = [
  {
    prop: 'bomLevel',
    label: '层级',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'code',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantityNumerator',
    label: '用量分子',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantityDenominator',
    label: '用量分母',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantityStandard',
    label: '标准用量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantityStandard'
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  }
]
