<template>
  <div class="process-detail-wrapper">
    <div class="process-detail-header flex-sbc">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
      <div class="right flex-sbc">
        <el-button v-if="saveButton" @click="checkData">工艺连续性检查</el-button>
        <el-button
          v-if="saveButton || permission('CopyVersion')"
          type="primary"
          :loading="buttonLoading"
          @click="modifyOrCopyVersion('copyVersion')"
        >
          {{ saveButton ? '保存' : '复制此版本' }}
        </el-button>
      </div>
    </div>

    <div class="process-detail-body flex-sb">
      <div v-if="!saveButton" class="left">
        <div style="border: 1px solid #DDE3EB">
          <div class="history-version" style="padding-right: 10px">
            <img src="../../../../../assets/information/process/历史版本@2x.png" style="width: 20px;margin-right: 5px">
            <div style="position: relative;top: 2px;word-break: keep-all">历史版本</div>
          </div>
          <ul class="version-wrapper">
            <li
              v-for="item in versionList"
              :key="item.id"
              :class="currentVersionId === item.id ? 'active' : ''"
              @click="viewVersion(item)"
            >
              <div class="li-left">
                <div class="name" :title="item.version">
                  <div class="ellipsis">{{ item.version }}</div>
                  <img v-if="item.isMain" src="../../../../../assets/information/process/主版本.png" style="width: 54px;margin-left: 10px">
                </div>
                <div class="info ellipsis" :title="`「 ${ item.creator } 」上传于${ item.createTime }`">
                  「 {{ item.creator }} 」上传于{{ item.createTime }}
                </div>
                <el-dropdown v-if="currentVersionId === item.id" class="dropdown" @command="versionMore">
                  <el-button icon="el-icon-more" class="el-icon-more-btn" />
                  <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                    <el-dropdown-item v-if="permission('ReNameVersion')" command="replaceName">重命名</el-dropdown-item>
                    <el-dropdown-item v-if="permission('EditVersion')" command="modifyVersion">编辑</el-dropdown-item>
                    <el-dropdown-item v-if="permission('SetMainVersion') && !item.isMain" command="setMain">设为主版本</el-dropdown-item>
                    <el-dropdown-item v-if="permission('DelVersion') && !item.isMain" command="delVersion">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="detail-card">
          <el-tabs v-model="baseInfoTabName" class="detail-tabs" type="border-card">
            <el-tab-pane label="基本信息" name="基本信息">
              <div class="card-bot">
                <MFormBuilder
                  v-if="saveButton && ['add', 'copy'].indexOf(params.type) !== -1"
                  ref="baseForm"
                  :form-list="baseFormList"
                  :form-data="baseFormData"
                >
                  <div slot="processFlowClassName">
                    <CURDSelect
                      :default-value="baseFormData.processFlowClassId"
                      :default-value-index="0"
                      api-name="ProcessType"
                      @change="selectName"
                    />
                  </div>
                </MFormBuilder>
                <template v-else>
                  <MFormBuilder
                    v-show="false"
                    ref="baseForm"
                    :form-list="baseFormList"
                    :form-data="baseFormData"
                  />
                  <el-row class="view-list" :gutter="24">
                    <!-- 暂时这么处理距离问题 -->
                    <el-col
                      v-for="(item, index) in columns.base"
                      :key="item.prop"
                      :title="getValue(item)"
                      :span="8"
                      :style="{marginBottom: index !== 6 ? '20px' : 0, display: 'inline-flex'}"
                    >
                      <div style="word-break: keep-all">{{ item.label }}：</div>
                      <div style="word-break: break-all">{{ getValue(item) }}</div>
                    </el-col>
                  </el-row>
                </template>
              </div>
            </el-tab-pane>
            <el-tab-pane label="工艺文件" name="工艺文件">
              <ProcessFile ref="ProcessFile" :base-list="baseFormData.processFiles" :read-only="!saveButton||!['add', 'copy'].includes(params.type)" />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="detail-card">
          <el-tabs v-model="activeTabName" class="detail-tabs" type="border-card" @tab-click="switchTab">
            <el-tab-pane label="工艺路线图" name="工艺路线图">
              <div class="card-bot process-card">
                <process-flow ref="processFlow" :base-form="$refs.baseForm" :read-only="!saveButton" :process-flow-detail="processFlowVersion" :handle-type="params.type" :can-import="baseFormData.isEnabled" :enable-step="enableStep" @addDetail="addDetails" @hideLoading="buttonLoading=false" :add-type="addType"/>
              </div>
            </el-tab-pane>
            <el-tab-pane label="BOM" name="BOM" :disabled="['add','copy'].includes(params.type)">
              <div slot="label">
                BOM
                <el-tooltip v-if="['add','copy'].includes(params.type)" class="item" effect="dark" content="工艺路线保存后才能查看" placement="top-start">
                  <i class="el-icon-question" />
                </el-tooltip>
              </div>
              <BOM ref="BOM" :process-flow-version="processFlowVersion" :base-form-data="baseFormData" />
            </el-tab-pane>
            <el-tab-pane label="质检项目" name="质检项目">
              <div slot="label">
                质检项目
              </div>
              <inspection-project ref="inspectionProject" :handle-type="params.type" :process-flow-id="parseInt(params.id)" :process-flow-version="processFlowVersion" @searchInspectTable="searchInspectTable" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <!--版本名称及主版本确认-->
    <MDialog v-model="versionVisible" width="400px" title="提示" @onOk="submitVersionForm">
      <MFormBuilder ref="versionForm" label-position="left" :form-list="versionFormList" :form-data="versionFormData" />
    </MDialog>
  </div>
</template>

<script>
import detailMixin from './detail-mixin'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import CURDSelect from '@/components/CURDSelect/CURDSelect'
import BOM from '@/components/ProcessFlow/BOM'
import ProcessFile from './ProcessFile.vue'
import baseSetting from '@/api/sets/base-setting'
import InspectionProject from '@/components/ProcessFlow/InspectionProject'

export default {
  name: 'ProcessDetail',
  components: { CURDSelect, ProcessFlow, BOM, ProcessFile, InspectionProject },
  mixins: [detailMixin],
  data() {
    return {
      activeTabName: '工艺路线图',
      baseInfoTabName: '基本信息',
      pageChangeCounter: 0,
      enableStep: false
    }
  },
  // beforeRouteLeave(to, from, next) {
  //   if (['replaceName', 'view'].includes(this.params.type) || this.dataJSONString === JSON.stringify(this.$refs.baseForm.form)) return next()
  //   this.$confirm('当前页有内容未保存，是否保存后离开?', '提示', {
  //     confirmButtonText: '保存',
  //     cancelButtonText: '直接离开',
  //     type: 'warning'
  //   }).then(async() => {
  //     this.modifyOrCopyVersion()
  //   }).catch(() => next())
  // },
  async created() {
    this.enableStep = await baseSetting.getSystemConfig({ configCode: 'procedure_step_config' })
  },
  mounted() {
    this.params = this.$route.query
    this.saveButton = this.params.type !== 'view'
    this.getAreaList()
      this.addType = this.params.addType
    // 绑定成品物料下拉加载指令
    this.baseFormList[8].directives[0].value = this.finishedMaterialLoadMore
    // 绑定成品物料远程搜索
    this.baseFormList[8].props.remoteMethod = (val) => { this.materialParams.search = val }
    if (this.params.type === 'add') {
      this.baseFormList[8].children[0].options = []
      this.baseFormData.type = parseInt(this.params.isType)
      this.baseFormData.materialsId = this.$route.query.materialsId ? parseInt(this.$route.query.materialsId) :''
      this.materialParams.materialsId = this.baseFormData.materialsId
      this.getFinishedMaterials(this.materialParams)
      this.getTypes()
      this.createProcessCode()
    }
    if (this.params.type !== 'add') {
      this.baseFormList[8].children[0].options = []
      this.getProcessDetailById(this.params.id)
    }
    if (this.needErp) {
      // this.getErpWarehouseList()
    }
  },
  methods: {
    switchTab(tab, e) {
      if (tab.label === 'BOM') this.$refs.BOM.init()
    },
    async checkData() {
      try {
        await this.$refs.processFlow.formatReturnData(true, true)
        this.$message.success('校验通过')
      } catch (error) {
        // 校验失败
      }
    }
  }
}
</script>

<style scoped lang="scss">
.process-detail-wrapper {
  height: 100%;

  .process-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .process-detail-body {
    width: 100%;
    height: calc(100% - 60px);
    .left {
      height: 100%;
      width: 242px;
      margin-right: 20px;
      display: inline-block;
      overflow-y: auto;
      overflow-x: hidden;
      .history-version {
        height: 70px;
        padding: 14px;
        width: 100%;
        background: #ffffff;
        border-radius: 4px 4px 0 0;
        display: flex;
        align-items: center;
      }
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 12px;
        color: #9597ae;
        background: #ffffff;
        border-radius: 0 0 4px 4px;
      }
      li {
        height: 70px;
        padding: 14px;
        .li-left {
          width: 100%;
          height: 100%;
          display: inline-flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          .name {
            font-size: 14px;
            color: #41456b;
          }
          .info {
            position: relative;
            left: -6px;
          }
          .dropdown {
            position: absolute;
            right: -20px;
            top: 13px;
            .el-icon-more-btn {
              transform: rotate(90deg);
              background: #e9eaec;
              border-radius: 2px;
              padding: 0 10px;
            }
          }
        }
        &:hover {
          background: #f0f2f5;
        }
        &.active {
          background: #f0f2f5;
        }
      }
    }

    .right {
      height: 100%;
      flex: 1;
      transition: all 0.5s;
      overflow: auto;
      .detail-card {
        margin-bottom: 20px;
      }
      .process-card {
        padding: 10px;
      }
    }
  }
}
</style>
