var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "materials-wrap" },
    [
      _c("div", { staticClass: "materials-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.back()
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [_vm._v("BOM转MBOM")]
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "Split",
            {
              attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
              on: {
                "update:offset": function($event) {
                  return _vm.$set(_vm.split, "offset", $event)
                }
              }
            },
            [
              _c(
                "template",
                { slot: "left" },
                [
                  _c("BOMLevel", {
                    ref: "bomlevel",
                    attrs: {
                      materialsBomId: _vm.materialsBomId,
                      processFlowVersionId: _vm.processFlowVersionId,
                      type: "changeBom",
                      chooseProcessList: _vm.chooseProcessList
                    },
                    on: {
                      nodeClick: _vm.setMaterialsClassId,
                      changeWidth: _vm.changeWidths,
                      onFilterDropss: _vm.onFilterDropss,
                      setMaterialsClass: _vm.setMaterialsClass
                    }
                  })
                ],
                1
              ),
              _c("template", { slot: "right" }, [
                _c("div", { staticClass: "process-detail-body" }, [
                  _c("div", { staticClass: "right1" }, [
                    _c("div", { staticClass: "detail-card" }, [
                      _c("div", { staticClass: "card-tit" }, [
                        _vm._v("工艺路线")
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-bot process-card" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.existMBom
                              },
                              on: { click: _vm.addProcess }
                            },
                            [_vm._v("新增工艺路线")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.existMBom
                              },
                              on: { click: _vm.importProcess }
                            },
                            [_vm._v("导入工艺路线模板")]
                          ),
                          _c("process-flow", {
                            ref: "processFlow",
                            attrs: {
                              "base-form": _vm.$refs.baseForm,
                              "read-only": true,
                              "process-flow-detail": _vm.processFlowVersion,
                              "handle-type": _vm.params.type,
                              "can-import": _vm.baseFormData.isEnabled,
                              importedList: _vm.importedList
                            },
                            on: {
                              addDetail: _vm.addDetails,
                              chooseEnter: _vm.chooseEnter,
                              hideLoading: function($event) {
                                _vm.buttonLoading = false
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            "append-to-body": true,
            title: "投入产出物料清单",
            width: "950px"
          },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.materialVisible,
            callback: function($$v) {
              _vm.materialVisible = $$v
            },
            expression: "materialVisible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "model-tit" },
            [
              _c("div", { staticClass: "tit" }, [_vm._v("目标工序")]),
              _c("el-input", {
                staticClass: "ipt",
                attrs: { disabled: "" },
                model: {
                  value: _vm.targetProcess,
                  callback: function($$v) {
                    _vm.targetProcess = $$v
                  },
                  expression: "targetProcess"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "model-tit" }, [
            _c(
              "div",
              [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    { staticClass: "tit", staticStyle: { width: "60%" } },
                    [_vm._v("投入物料")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tit",
                      staticStyle: { width: "10%", "text-align": "center" }
                    },
                    [_vm._v("标准用量")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tit",
                      staticStyle: { width: "10%", "text-align": "center" }
                    },
                    [_vm._v("使用量")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tit",
                      staticStyle: { width: "10%", "text-align": "center" }
                    },
                    [_vm._v("损耗率")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tit",
                      staticStyle: { width: "10%", "text-align": "center" }
                    },
                    [_vm._v("关键物料")]
                  )
                ]),
                _c("BomTree", {
                  ref: "zTrees",
                  attrs: {
                    view: true,
                    nodes: _vm.dropNode,
                    type: "chooseChangeBom"
                  },
                  on: { chooseNode: _vm.chooseNode }
                })
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: { "append-to-body": true, title: "选择要导入的工艺路线" },
          on: { onOk: _vm.submitProcessForm },
          model: {
            value: _vm.importProcessVisible,
            callback: function($$v) {
              _vm.importProcessVisible = $$v
            },
            expression: "importProcessVisible"
          }
        },
        [
          _c("el-cascader", {
            ref: "cascader",
            staticStyle: { width: "100%" },
            attrs: {
              options: _vm.processFlows,
              props: {
                lazy: true,
                lazyLoad: _vm.loadOptions,
                label: "processFlowFullName",
                value: "id"
              }
            },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.processFlowFullName,
              callback: function($$v) {
                _vm.processFlowFullName = $$v
              },
              expression: "processFlowFullName"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }