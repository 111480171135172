<template>
    <div class="materials-wrap">
        <div class="materials-detail-header">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">BOM转MBOM</span>
      </div>
    </div>
      <div class="bottom">
        <Split :offset.sync="split.offset" :height="splitHeight" >
          <template slot="left">
            <BOMLevel ref="bomlevel" @nodeClick="setMaterialsClassId" @changeWidth="changeWidths" :materialsBomId="materialsBomId" :processFlowVersionId="processFlowVersionId"
            :type="'changeBom'" @onFilterDropss="onFilterDropss" @setMaterialsClass="setMaterialsClass" :chooseProcessList="chooseProcessList"/>
          </template>
          <template slot="right">
            <div class="process-detail-body">
      <div class="right1">
        <div class="detail-card">
            <div class="card-tit">工艺路线</div>
              <div class="card-bot process-card">
                <el-button type="primary" @click="addProcess" :disabled="existMBom">新增工艺路线</el-button>
                <el-button type="primary" @click="importProcess" :disabled="existMBom">导入工艺路线模板</el-button>
                <process-flow ref="processFlow" :base-form="$refs.baseForm" :read-only="true" :process-flow-detail="processFlowVersion" :handle-type="params.type" :can-import="baseFormData.isEnabled" @addDetail="addDetails" @chooseEnter="chooseEnter" @hideLoading="buttonLoading=false" :importedList="importedList"/>
              </div>
        </div>
      </div>
            </div>
          </template>
        </Split>
      </div>
      <MDialog
      v-model="materialVisible"
      :append-to-body="true"
      title="投入产出物料清单"
      width="950px"
      @onOk="submitForm"
    >
      <div class="model-tit">
        <div class="tit">目标工序</div>
        <el-input v-model="targetProcess" class="ipt" disabled/>
      </div>
      <div class="model-tit">
        <div>
        <div style="display:flex">
        <div class="tit" style="width:60%">投入物料</div>
        <div class="tit" style="width:10%;text-align:center">标准用量</div>
        <div class="tit" style="width:10%;text-align:center">使用量</div>
        <div class="tit" style="width:10%;text-align:center">损耗率</div>
        <div class="tit" style="width:10%;text-align:center">关键物料</div>
        </div>
        <BomTree ref="zTrees" :view="true" :nodes="dropNode" @chooseNode="chooseNode" :type="'chooseChangeBom'"/>
        </div>
      </div>
    </MDialog>

    <MDialog
      v-model="importProcessVisible"
      :append-to-body="true"
      title="选择要导入的工艺路线"
      @onOk="submitProcessForm"
    >
      <el-cascader
        ref="cascader"
        v-model="processFlowFullName"
        style="width:100%"
        :options="processFlows"
        :props="{lazy: true,lazyLoad: loadOptions,label:'processFlowFullName',value:'id'}"
        @change="handleChange"
      />
    </MDialog>
    </div>
  </template>

  <script>
  import FunctionHeader from '@/components/FunctionHeader'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { columnsDetail } from './columns'
  import maApi from '@/api/information/materials-attribute'
  import api from '@/api/information/bom'
  import { getUUid } from '@/utils'
  import { Encrypt } from '@/utils/sercet'
  import Split from '@/components/Split/Split'
  import BOMLevel from '@/components/BOMClass/BOMLevel'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import batchExportFront from '@/mixin/batch-export-front'
  import fixCommonPage from '@/mixin/fix-common-page'
  import detailMixin from './detail-mixin'
  import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
  import BomTree from '@/components/Tree/BomTree'
  import papi from '@/api/production/completeSet'
  import $ from 'jquery'

  export default {
    name: 'changeToMbom',
    components: { NewPageOpen, BOMLevel, Split, FunctionHeader, SearchForm,ProcessFlow, BomTree },
    mixins: [batchExportFront, fixCommonPage,detailMixin],
    data() {
      return {
        functionHeader: {
          searchVal: '',
          tags: [],
          importName: 'materials',
          exportName: 'exportBomLevelDownMList',
          exportParams: {}
        },
        columnsDetail,
        searchFormData: {},
        searchFormatData: [],
        split: {
          offset: 32
        },
        materialsClassId: '',
        defaultSearch: {
          searchVal: Encrypt(JSON.stringify(
            [{
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }]
          ))
        },
        materialsBomId:+this.$route.query.id,
        materialVisible:false,
        templateName:'',
        dropNode:'',
        processNodes:'',
        targetProcess:'',
        outputMaterial:[],
        outputQuantity:'',
        newProcessNodes:'',
        materialsId:'',
        materialsList:[],
        processId:'',
        processFlowVersionId:0,
        chooseNodes:[],
        existMBom:false,
        importProcessVisible:false,
        processFlowFullName: '',
        processFlows: [],
        chooseProcessList:[]
      }
    },
    watch: {
      chooseNodes(val) {
      if(val){
      this.materialsList = this.flattenArray(val)
      }
  }
},
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 39
      },
      splitHeight() {
        return this.$store.state.app.contentHeight - 235 + 70 - 39
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    mounted() {
    },
    methods: {
    addMaterials(){
      this.materialVisible = true
    },
    chooseNode(node){
       this.chooseNodes = this._.cloneDeep(node)
       },
       onFilterDropss(node,nodes){
        let a = this._.cloneDeep(node)
        this.dropNode = this.hasFalseDropChild(a)
        if(this.dropNode.length === 1 && node[0].children){
          this.outputMaterial = node
           this.outputQuantity = node[0].quantity
        }else{
          this.outputMaterial = []
          this.outputQuantity = ''
        }
        if(this.processNodes && node){
            this.materialVisible = true
            this.materialsList = nodes
            this.processNodes = ''
        }
    },
    hasFalseDropChild(val) {
      let result = [];
      for (let i = 0; i < val.length; i++) {
        const currentNode = val[i];
        if (currentNode.drop && !currentNode.children) {
          result.push(currentNode);
        }
        if (currentNode.children && currentNode.children.length > 0) {
          const filteredChildren = this.hasFalseDropChild(currentNode.children);
          if (filteredChildren.length > 0) {
            currentNode.children = filteredChildren;
            result.push(currentNode);
          } else if (currentNode.drop) {
            delete currentNode.children
            currentNode.isParent = false
            result.push(currentNode);
          }
        }
      }
      return result;
  },
  submitProcessForm(done) {
      done()
      if (this.processFlowFullName) {
        this.$confirm('导入后，将覆盖原有工艺，是否导入？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          this.getProcessDetailById(this.processFlowFullName[1],this.processFlowFullName[0])
          this.importProcessVisible = false
        })
        return false
      }
      this.$message.error('请选择工艺路线')
    },
    assignChecked(item){
        if (item.children) {
          item.children = item.children.map(child => {
            return this.assignChecked(child);
          });
        }
        return {
          ...item,
          checked: true
        }
    },
    chooseEnter(node,val2){
         this.newProcessNodes = node
        this.processNodes = node
        if(node){
            this.targetProcess = val2
        }
    },
    changeWidths(val){
        this.split.offset = val ? 32 : 3
    },
      back() {
        this.$router.push({ name: 'bomDetail', query: { id: this.materialsBomId }})
      },
      setMaterialsClassId(id) {
        this.materialsClassId = id
      },
      setMaterialsClass(val){
        this.existMBom = val[0].existMBom
        this.materialsId = val[0].materialsId
        this.processFlowVersionId = val[0].processFlowVersionId
        console.log(this.processFlowVersionId)
        if(this.processFlowVersionId){
          this.getProcessDetailById(this.processFlowVersionId,val[0].processFlowId)
        }
      },
      importProcess(){
        this.importProcessVisible = true
        this.getBomProcessFlowList(this.materialsId)
        // this.$refs.processFlow.importProcess();
      },
      // 获取工艺路线
      async getBomProcessFlowList(materialsId) {
        const res = await papi.getBomProcessFlowList({ materialsId })
        if (res) {
          this.processFlows = res || []
        }
    },
       // 懒加载二级选项
       async loadOptions(node, resolve) {
        if (!this.processFlows.length) return
        const { level, value } = node
        node.children = []
        const res = await papi.getProcessFlowDetails(value, true)
        if (res) {
          const nodes = res.versionList.map(item => ({
            id: item.id,
            processFlowFullName: item.version,
            leaf: level >= 1
          }))
          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          resolve(nodes)
        }
    },
        // 工艺路线
        handleChange(val) {
       },
      // 获取物料类型
      async getMaterialsTypeList() {
        const res = await maApi.getMaterialsTypeList(this.defaultSearch)
        if (res) {
          this.searchFormList[1].children[0].options = res
        }
      },
      addProcess(){
      const isType = 0
      this.$router.push({ name: 'processDetail', query: { type: 'add', isType: isType,addType:'mbom',bomId:this.materialsBomId,materialsId:this.materialsId }})
      },
    //   // 获取物料属性
    //   async getMaterialsAttributeList() {
    //     const res = await maApi.getMaterialsAttributeList()
    //     if (res) {
    //       this.searchFormList[2].children[0].options = res
    //     }
    //   },
      // 获取物料数据
      async getTableData(condition, callback) {
        if(this.materialsClassId){
          const { page, search, order } = condition
        const searchCondition = {
          materialsBomLevelId: this.materialsClassId,
          search: this.functionHeader.searchVal
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getBomLevelDownList({
          page: page.currentPage,
          size: page.pageSize,
          materialsBomLevelId:this.materialsClassId,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
        }else{
          callback()
        }

      },
  flattenArray(arr) {
    let flattened = []
    arr.forEach(item => {
      if (!item.children) {
        flattened.push({ ...item });
      } else {
        flattened.push({ ...item });
        flattened = flattened.concat(this.flattenArray(item.children));
      }
    })
    return flattened;
  },
      async submitForm(callback) {
        let a
       a = this.insertIconSkin1(this.materialsList)
       let obj = {
        materialsBomId:this.materialsBomId,
        procedureDetailId:this.newProcessNodes,
        inputMaterialsList:a.map(item=>{
            return {
                parentMaterialsId:item.parentMaterialsId,
                materialsId:item.materialsId,
                quantity:item.availableQuantity,
                isKeyMaterials:item.isKeyMaterials ? 1:0,
                attritionRate:item.rate
            }
        })
       }
       this.insertIconSkin1(this.materialsList)
       const res = await api.bindProcedureMatreials(obj)
       if(res){
        this.$message.success('绑定成功')
        this.materialVisible = false
        this.$refs.bomlevel.getMaterialsClassList()
        callback(true)
       }else{
        callback()
       }
      },
       insertIconSkin1(arr) {
       arr.forEach(obj => {
        var inputs = $(`#text1_${obj.id}`)
        var inputs2 = $(`#text2_${obj.id}`)
        var inputs3 = $(`#text3_${obj.id}`)
        inputs.each(function() {
        obj.availableQuantity = $(this).val()
        })
        inputs2.each(function() {
        obj.rate = $(this).val()
        })
        inputs3.each(function() {
        obj.isKeyMaterials = $(this).prop('checked')
        })
    });
    return arr
      },
      // 查询物料
      searchTable(data) {
        this.searchFormatData = data
      },
      add() {
        this.$router.push({ name: 'materialsDetail', query: { type: 'add' }})
      },
      // 更多操作
      more(row, command) {
        const functions = {
          modify: () => {
            this.modifyOrCopy(row, command)
          },
          copy: () => {
            this.modifyOrCopy(row, command)
          },
          del: () => {
            this.del(row)
          },
          switch: () => {
            this.switch(row)
          }
        }
        functions[command]()
      },
      modifyOrCopy(row, command) {
        this.$router.push({ name: 'materialsDetail', query: { id: row.id, type: command }})
      },
      async batchDel() {
        const res = await api.delBatchMaterials(this.exportSelectionIds)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData(true)
        }
      },
      // 删除物料
      del(row) {
        this.currentType = 'del'
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.delMaterials({ id: row.id })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData()
          }
        })
      },
      // 更改物料状态
      async switch(row) {
        const res = await api.switchStatus(row.isEnabled ? 'disable' : 'enable', 'materials', row.id)
        if (res) {
          this.$message.success(`${row.name}${row.isEnabled ? '停用' : '启用'}成功`)
          this.$refs.mTable.setTableData()
        }
      },
      viewDetail(row) {
        this.$message.info('敬请期待！')
      },
      onSuccess() {
        this.$refs.mTable.setTableData()
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .materials-wrap {
    width: 100%;
    .materials-detail-header {
    width: 100%;
    padding: 10px 0;
  }
    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
      background: #fff;
      .materials-info {
        padding: 10px;
      }
    }
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
  ::v-deep {
    .params-card {
      .body {
        padding: 0 !important;
      }
      .params {
        width: 100% !important;
      }
      .el-form-item__label {
        padding: 0 12px 0 0 !important;
      }
    }
  }
  .model-tit {
    .min-input{
        width:100px;
    }
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 700;
    color: #4a4f76;
    padding:10px 0;
  }

}
  </style>
