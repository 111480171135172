<template>
    <div class="unit-wrap">
     <div style="display:flex;justify-content:space-between">
        <div :style="{width:type=== 0 ? '100%':'50%'}">
        <FunctionHeader
        v-model="functionHeader.searchVal"
        :tags="functionHeader.tags"
        :search-title="searchTitle"
        :columns-key="columnsKey"
        :import-name="functionHeader.importName"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable ref="mTable" :columns="getColumns" :height="heightAll" :columns-key="columnsKey" :set-data-method="getTableData" @row-click="handleRowClick" :row-style="getRowStyle">
        <div slot="headerSlot" >
            <div style="display:flex;justify-content: center;color:#000">
            <div @click="viewDetail(1)" title="物料需求" style="margin-right:15px;cursor: pointer;">
            <i class="el-icon-d-arrow-right"/>
            </div>
            <div @click="viewDetail(2)" title="物料供应" style="margin-right:15px;cursor: pointer;">
            <i class="el-icon-d-arrow-left"/>
            </div>
        </div>
        </div>
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

        <div slot="materialsTypeName" slot-scope="{ row }">
          <div>{{ row.materialsTypeName }}</div>
        </div>
        <div slot="missMaterialsRate" slot-scope="{ row }">
          <div>{{ row.missMaterialsRate}}%</div>
        </div>
      </MTable>
        </div>
      <div v-if="type === 1" title="物料总览" style="cursor: pointer;margin:53px 8px 0">
            <i class="el-icon-d-arrow-left" @click="viewDetail(0)" />
            </div>
            <div v-if="type=== 1" style="width:48%;margin-top:53px">
                <MTable ref="mTables" :columns="columnsRequirement" :show-page="false" :columns-setting="false" :height="height" :columns-key="columnsKey" :data="requireList" :is-tree-data="true" :default-expand-all="isExpand">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="missMaterialsRate" slot-scope="{ row }">
          <div>{{ row.missMaterialsRate}}%</div>
        </div>
      </MTable>
            </div>
    <div v-if="type === 2" title="物料总览" style="margin:53px 8px 0;cursor: pointer;">
    <i class="el-icon-d-arrow-right" @click="viewDetail(0)" />
    </div>
    <div v-if="type=== 2" style="width:48%;margin-top:53px">
      <MTable
      ref="mTabless"
      :is-tree-data="true"
      :tree-props="{children: 'children'}"
      :show-page="false"
      :columns="columnsSupply"
      :data="supplyList"
      :columns-setting="false"
      default-expand-all
      :row-style="getRowDelayStyle"
    >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
    </MTable>
            </div>
     </div>
    </div>
  </template>

  <script>
  import FunctionHeader from '@/components/FunctionHeader/index'
  import DelPopover from '@/components/DelPopover/index'
  import {columnsAll,columnsRight,columnsRequirement,columnsSupply} from './columns'
  import api from '@/api/information/materials-balance'
  import conventionMixin from '@/mixin/conventional-page'

  export default {
    name: 'materialsBalance',
    components: { FunctionHeader, DelPopover },
    mixins: [conventionMixin],
    props:{
      projectId:{
        type: Number,
         default: undefined
      }
    },
    data() {
      return {
        api,
        params: {
          radio: 'MaterialsBalance'
        },
        functionHeader: {
          searchVal: '',
          tags: [
          ],
          importName: '',
          exportName: 'exportMaterialsBalanceList',
          exportParams: {}
        },
        columnsAll,
        columnsRight,
        visible: false,
        formData: {},
        currentType: 'add',
        searchTitle: '请输入物料名称/编号/规格',
        type:0,
        columnsRequirement,
        requireList:[],
        isExpand:true,
        selectedRow:'',
        columnsSupply,
        supplyList:[]
      }
    },
    computed: {
      height() {
        return this.projectId ? this.$store.state.app.contentHeight - 235 - 10 - 42 : this.$store.state.app.contentHeight - 235 - 10
      },
      heightAll() {
        return this.projectId ? this.$store.state.app.contentHeight - 235 - 10 + 35 - 42: this.$store.state.app.contentHeight - 235 - 10 + 35
      },
      columnsKey() {
        return this.$route.name + this.type
      },
    getColumns() {
        let columnRequire = this._.cloneDeep(this.columnsAll)
        if(this.type === 1){
            columnRequire.splice(7)
        }else if(this.type === 2){
            columnRequire = this.columnsRight
        }
      return columnRequire
    }
    },
    methods: {
      async getTableData(condition, callback) {
        const { page, search, order } = condition

        const data = Object.assign({}, ...this.searchFormatData)
        const searchCondition = {
          search: this.functionHeader.searchVal,
          ...data
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          size: page.pageSize,
          projectId:this.projectId,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      viewDetail(type){
        this.type = type
        if(type){
            this.functionHeader.exportName = ''
        }else{
            this.functionHeader.exportName = 'exportMaterialsBalanceList'
        }
        this.$refs.mTable.setTableData(true)
      },
    //选中行
      async handleRowClick(row){
        if(this.type === 1){
            this.selectedRow = row
            const res = await api.getMaterialsBalanceRequireDetail({
                materialsId:row.materialsId
            })
            if(res){
                this.requireList = res
            }
        }else if(this.type === 2){
            this.selectedRow = row
            const res = await api.getMaterialsBalanceSupplyDetail({
                materialsId:row.materialsId
            })
            if(res){
              this.supplyList = res
            }
        }
      },
      getRowStyle({row}){
      if(row === this.selectedRow){
        return { background: 'rgb(165,194,238)' }
      }
      },
      getRowDelayStyle({row}){
      if(row.overdue){
        return {  background: 'rgba(216, 84, 84, 0.504)'}
      }
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .unit-wrap {
    padding: 10px;
  }
  </style>
