<template>
  <div class="orderAdd">

    <div class="detail-card" style="padding: 10px">
        <div class="m-table">
        <el-table
          ref="table"
          :data="dataProcess"
          :show-header="true"
          :default-expand-all="isExpand"
          v-loading="loading"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                :ref="`expandFirstTableRef${props.$index}`"
                :data="props.row.children"
                class="ellipsis-table"

                @expand-change="onExpand"
              >
                <el-table-column type="expand">
                  <template slot-scope="nestedScope">
                    <el-table
                      :ref="'expandTable' + nestedScope.row.procedureLocationCode"
                      :data="nestedScope.row.children"
                      class="ellipsis-table"
                      :maxHeight="tableHeight"
                      row-key="id"
                      @selection-change="
                        (data) =>
                          handleSelectionChange(
                            data,
                            nestedScope.$index,
                            props.$index
                          )
                      "
                    >
                      <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        :selectable="selectable"
                      >
                      </el-table-column>
                      <el-table-column prop="index" label="序号">
                        <template slot-scope="scope">
                          {{ scope.$index + 1 }}
                        </template>
                      </el-table-column>
                      <el-table-column prop="materialsType" label="物料类型">
                        <template slot-scope="scope">
                          {{ scope.row.materialsType }}
                        </template>
                      </el-table-column>
                      <el-table-column prop="materialsCode" label="物料编号"></el-table-column>
                      <el-table-column
                        prop="materialsName"
                        label="物料名称"
                        width="200"
                      ></el-table-column>
                      <el-table-column prop="specifications" label="物料规格"></el-table-column>

                      <el-table-column prop="isKeyMaterials" label="是否关键物料">
                        <template slot-scope="scope">
                          {{ scope.row.isKeyMaterials ? "是" : "否" }}
                        </template>
                      </el-table-column>
                      <el-table-column prop="mainUnitName" label="主单位"></el-table-column>
                      <el-table-column
                        prop="requirementQuantity"
                        label="需求数量"
                      ></el-table-column>
                      <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>

                      <el-table-column prop="applyQuantity" label="申请数量" width="130">
                        <template slot-scope="scope">
                          <el-input-number
                            v-model="scope.row.applyQuantity"
                            :min="0"
                            controls-position="right"
                            :step="1"
                            style="width: 90%"
                          ></el-input-number>
                        </template>
                      </el-table-column>
                      <el-table-column prop="notReceiveQuantity" label="未领数量"></el-table-column>
                      <el-table-column
                        prop="appliedUnissuedQuantity"
                        label="申请未发数量"
                      ></el-table-column>

                      <el-table-column prop="requireDate" label="需求日期"></el-table-column>
                      <!--<el-table-column-->
                        <!--prop="availableStockQuantity"-->
                        <!--label="自由库存"-->
                      <!--&gt;</el-table-column>-->
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column prop="procedureName" label="工序名称"></el-table-column>
                <el-table-column prop="procedureLocationCode" label="工序定位码"></el-table-column>
                <el-table-column prop="requirementQuantity" label="需求数量"></el-table-column>
                <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>
                <el-table-column prop="notReceiveQuantity" label="未领数量"></el-table-column>
                <el-table-column
                  prop="keyMaterialsRequirementQuantity"
                  label="关键料数量"
                ></el-table-column>
                <el-table-column prop="procedureCompleteRate" label="工序齐套率">
                  <template slot-scope="scope"> {{ scope.row.procedureCompleteRate }}% </template>
                </el-table-column>
                <el-table-column prop="notReceiveCompleteRate" label="未领齐套率">
                  <template slot-scope="scope"> {{ scope.row.notReceiveCompleteRate }}% </template>
                </el-table-column>
                <el-table-column prop="keyMaterialsCompleteRate" label="关键料齐套率">
                  <template slot-scope="scope">
                    {{ scope.row.keyMaterialsCompleteRate }}%
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="index" label="序号">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="receiptNumber" label="单据编号"></el-table-column>
          <el-table-column prop="receiptName" label="单据名称"></el-table-column>
          <el-table-column prop="receiptType" label="单据类型">
            <template slot-scope="scope">
              {{ scope.row.receiptType | receiptType }}
            </template>
          </el-table-column>
          <el-table-column prop="receiptStatus" label="单据状态">
            <template slot-scope="scope">
              {{ scope.row.receiptStatus | receiptStatus }}
            </template>
          </el-table-column>
          <el-table-column prop="requirementQuantity" label="需求总量"></el-table-column>
          <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>
          <el-table-column prop="notReceiveQuantity" label="未领数量"></el-table-column>
          <!--<el-table-column prop="lockedQuantity" label="锁库数量"></el-table-column>-->
          <el-table-column prop="requireDate" label="需求时间"></el-table-column>
          <el-table-column prop="projectNumber" label="项目编号"></el-table-column>
          <el-table-column prop="projectName" label="项目名称"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
        </el-table>
        </div>
      <!-- <div class="page-container">
<el-pagination
:current-page="page.currentPage"
:page-sizes="page.pageSizes"
:page-size="page.pageSize"
layout="total, sizes, prev, pager, next, jumper"
:total="page.total"
@size-change="handleSizeChange"
@current-change="handleCurrentChange"
/>
</div> -->
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="returnList">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </div>
    <div class="lines" />
  </div>
</template>
  <script>
import api from "@/api/information/materials-apply";
import { docType, docStatus } from "@/config/options.config";
import FunctionHeader from "@/components/FunctionHeader/index";
export default {
  name: "materialsBatchApply",
  components: { FunctionHeader },
  filters: {
    receiptType(val) {
      if (val || val === 0) return docType.find((item) => item.id === val).name;
    },
    receiptStatus(val) {
      return docStatus.find((item) => item.id === val).name;
    },
  },
  data() {
    return {
      api,
      docType,
      docStatus,
      baseFormData: {},
      functionHeader: {
        searchVal: "",
        importName: "",
        exportName: "",
        exportParams: {},
        tags: [],
      },
      expandedRows: [],
      selectMaterials: [],
      searchId: undefined,
      isExpand: false,
      applyMethodsList: [
        {
          id: 0,
          name: "按工序",
        },
        {
          id: 1,
          name: "按模组",
        },
        {
          id: 2,
          name: "按物料",
        },
      ],
      methodId: "",
      dataProcess: [],
      originProcessSelectionDatas: [],
      processSelectionDatas: [],
      params: {},
      loading:false
    };
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29;
    },
    tableHeight() {
      return this.$store.state.app.contentHeight - 196 - 10 -50;
    },
    childTableHeight() {
      return this.$store.state.app.contentHeight - 56 - 44 - 12 - 37 - 40 - 25 - 70 - 100;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "materialsApplyList") {
      this.$store.dispatch("tagsView/delCachedView", to);
    }
    next();
  },
async created() {
    this.params = JSON.parse(this.$route.query.selected)
  },
  mounted() {
    this.getProcedureMaterialsListPage();
  },
  methods: {
    async getProcedureMaterialsListPage() {
        this.loading = true
      const searchCondition = {
        workOrderIds: this.params.workOrderIds.split(","),
        mode: this.params.mode,
        modeSearch: this.params.modeSearch,
      };
      const res = await api.getRequirementOrderBatchList({
        //  page: this.page.currentPage,
        //  size:this.page.pageSize,
        ...searchCondition,
      });
      if (res) {
        this.loading = false
        this.dataProcess = res.map((item) => {
          return {
            ...item,
            children: item.children
              ? item.children.map((items) => {
                  return {
                    ...items,
                    children: items.children
                      ? items.children.map((obj) => {
                          return {
                            ...obj,
                            applyQuantity:
                              obj.notReceiveQuantity - obj.appliedUnissuedQuantity,
                            selectable:
                              obj.notReceiveQuantity - obj.appliedUnissuedQuantity > 0
                                ? false
                                : true,
                          };
                        })
                      : [],
                  };
                })
              : [],
          };
        });
        //         this.page.total = res.total
      }
    },
    onExpand(row) {
      let _this = this;
      _this.getExpandMaterialsData(row);
    },
    // 获取工序展开物料
    async getExpandMaterialsData(row) {
      let _this = this;
      let procedureLocationCode = row.procedureLocationCode;
      let expandTable = "expandTable" + procedureLocationCode;
      // table展开时，根据之前选中的选项通过toggleRowSelection点击checkbox
      _this.$nextTick(function () {
        if (_this.$refs[expandTable]) {
          let hasSelections =
            _this.processSelectionDatas.length > 0
              ? _this.processSelectionDatas.length
              : undefined > 0;
          if (hasSelections) {
            // _this.isClassTableExpanded = true
            let selectedIds = _this.processSelectionDatas.map((mSelect) => mSelect.id);
            if (row.children) {
              row.children.forEach((row) => {
                if (selectedIds.includes(row.id)) {
                  _this.$refs[expandTable].toggleRowSelection(row, true);
                }
              });
            }
          }
        }
        // _this.isClassTableExpanded = false
      });
    },
    selectable(row) {
      if (row.selectable) {
        return false;
      }
      return true;
    },
    handleSelectionChange(data, index, indexs) {
      if (!this.originProcessSelectionDatas[indexs]) {
        this.originProcessSelectionDatas[indexs] = []; // 如果 indexs 位置没有初始化为数组，先初始化为数组
      }
      this.originProcessSelectionDatas[indexs][index] = data;
      this.processSelectionDatas = this.originProcessSelectionDatas.flat(2);
    },
    async save() {
      const res = await api.batchCreateApply({
        itemList: this.processSelectionDatas.map((item) => {
          return {
            receiptId: item.receiptId,
            procedureId: item.procedureId,
            procedureLocationCode: item.procedureLocationCode,
            materialsId: item.materialsId,
            applyQuantity: item.applyQuantity,
          };
        }),
      });
      if (res) {
        this.$message.success("新增成功");
        this.$router.push({ name: "materialsApplyList" });
      }
    },
    returnList() {
      this.$router.go(-1);
      localStorage.removeItem("applyMaterials");
    },
  },
};
</script>

<style scoped lang="scss">
.orderAdd {
  width: 100%;
//   height: 100%;
  .input-new-tag {
    width: 130px;
  }
  .button-new-tag {
    height: 32px;
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  .e-btn {
    margin-bottom: 10px;
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
