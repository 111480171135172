import { status } from '@/config/options.config'

/** write by luomingzhi */

export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    minWidth: 80,
    hiddenSearch: true,
    slotName: 'index'
  },
  {
    prop: 'code',
    label: '工序编码',
    slotName: 'code',
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'name',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'procedureClassName',
    label: '工序分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'type',
    label: '工序类别',
    slotName: 'type',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'groupName',
    label: '所属工序段',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150
  },
  {
    prop: 'workCenterName',
    label: '工作中心',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150
  },
  {
    prop: 'station',
    label: '可执行工位',
    slotName: 'station',
    sortable: false,
    hiddenSearch: true,
    minWidth: 130
  },
  {
    prop: 'isEnabled',
    label: '工序状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 170
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]

export const stationColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '工位编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工位名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'areaName',
    label: '所属产线',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentName',
    label: '工位设备',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '工位状态',
    slotName: 'isEnabled',
    sortable: false,
    hiddenSearch: true
  }
]

export const groupColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index'
  },
  {
    prop: 'name',
    label: '工序段名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '工序段状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]
