import { base,columnsWork,columnsOccupyMaterials} from './columns'
import { baseFormList, versionFormList } from '@/views/information/production/process/detail/form-list'
import api from '@/api/information/materials-require'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      processFlowClassId: '',
      processFlowClassName: '',
      params: {},
      buttonLoading: false,
      baseFormData: { processFlowVersion: {}, processFlowClassName: '' },
      saveButton: true,
      columns: {
        base
      },
      columnsWork,
      baseFormList,
      formOptions: {
        areaOptions: [],
        erpWarehouseOptions: []
      },
      versionList: [],
      currentVersionId: null,
      currVersionName: null,
      processFlowVersion: {},
      versionFormList,
      versionFormData: {},
      versionVisible: false,
      formatReturnData: {},
      materialParams: { status: 1, page: 1, size: 20, search: '',materialsId:'' },
      workList:[{},{}],
      visible:false,
      dataProcess:[],
      page: {
        default: 20,
        pageSize: 20,
        pageSizes: [10, 20, 30, 50, 100, 150, 200],
        total: 0,
        currentPage: 1
      },
      functionHeader: {
        searchVal: '',
        importName: '',
        exportName: '',
        exportParams: {},
        tags: [
          //   {
          //   hidden: !this.permission('batchLock'),
          //   tagName: 'el-button',
          //   props: { type: 'primary', size: 'small' },
          //   style: { width: 'auto', background: '#607FFF'},
          //   innerText: '批量锁库',
          //   on: {
          //     click: () => this.batchOccupy(true)
          //   }
          // },
          {
            hidden: !this.permission('Export'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF'},
            innerText: '全部导出',
            on: {
              click: () => this.batchExport2()
            }
          },
          // {
          //   hidden: !this.permission('Export'),
          //   tagName: 'el-button',
          //   props: { type: 'primary', size: 'small' },
          //   style: { width: 'auto', background: '#607FFF'},
          //   innerText: '领料',
          //   on: {
          //     click: () => this.batchReceive('add')
          //   }
          // }
          ],
      },
      functionProcessHeader: {
        searchVal: '',
        importName: '',
        exportName: '',
        exportParams: {},
        tags: [
          // {
          //   hidden: !this.permission('Export'),
          //   tagName: 'el-button',
          //   props: { type: 'primary', size: 'small' },
          //   style: { width: 'auto', background: '#607FFF'},
          //   innerText: '全部导出',
          //   on: {
          //     click: () => this.batchExport()
          //   }
          // },
          //   {
          //   hidden: !this.permission('Apply'),
          //   tagName: 'el-button',
          //   props: { type: 'primary', size: 'small' },
          //   style: { width: 'auto', background: '#607FFF'},
          //   innerText: '领料',
          //   on: {
          //     click: () => this.batchReceive('add',true)
          //   }
          // },
          // {
          //   hidden: !this.permission('Return'),
          //   tagName: 'el-button',
          //   props: { type: 'primary', size: 'small' },
          //   style: { width: 'auto', background: '#607FFF'},
          //   innerText: '退料',
          //   on: {
          //     click: () => this.batchReceive('add',false)
          //   }
          // },
          ],
      },
      occupyList:[],
      selectionDatas:[],
      originProcessSelectionDatas:[],
      processSelectionDatas:[],
      columnsOccupyMaterials,
      moduleList:[],
      processList:[],
      processId:'',
      moduleId:'',
      isChooseProcess:false,
      processFlowId:'',
      processFlowVersionId:'',
      isChooseModule:false,
      isExpand:false
    }
  },
  computed: {
    height() {
        return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29
      },
      tableHeight() {
        let { height } = this.$attrs
        const maxHeight = this.$attrs['max-height'] || this.$attrs.maxHeight
        if (maxHeight) return null
        if (!height) height = this.$store.state.app.contentHeight - 56 - 44 - 12 - 37 - 40 - 25
        return this.showPage ? height - 44 : height - 5
      },
  },
  watch: {
  },
  beforeMount() {
  },
  mounted() {
  },
  methods: {
    async batchExport() {
      const params = {
        receiptId:this.params.id,
        receiptType:this.params.type,
        search: this.functionProcessHeader.searchVal
      }
      const res = await api.exportProcedureMaterialsList(params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      }
    },
    async batchExport2() {
      const params = {
        receiptId:this.params.id,
        receiptType:this.params.type,
        search: this.functionProcessHeader.searchVal
      }
      const res = await api.exportWorkOrderMaterialsList(params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      }
    },
    // 领料
    async batchReceive(type,isApply){
      let data
      if(this.activeTabName === '工序物料'){
        const res = await api.synchronizedApplyQuantity({
          receiptId:this.params.id,
          itemList:this.processSelectionDatas.map(item =>{
            return {
              procedureId:item.procedureId,
              procedureLocationCode:item.procedureLocationCode,
              materialsId:item.materialsId
            }
          })
        })
        if(res){
          data = res.map(item=>{
              return {
                ...item,
                applyQuantity: item.notReceiveQuantity - item.appliedUnissuedQuantity
              }
            })
        }
      }else{
        data = this.selectionDatas
      }
      if(isApply){
        this.$router.push({ name: 'materialsApplyDetail', query: { type, id:this.params.id,receiptType:this.params.type}})
      }else{
        this.$router.push({ name: 'materialsApplyReturnDetail', query: { type, id:this.params.id,receiptType:this.params.type }})
      }
      const uniqueArr = data.reduce((unique, item) => {
        if (!unique.some(obj => JSON.stringify(obj) === JSON.stringify(item))) {
          unique.push(item)
        }
        return unique
      }, [])
      let uniqueFilterArr = uniqueArr.filter(item =>item.applyQuantity > 0)
      localStorage.setItem('applyMaterials', JSON.stringify(uniqueFilterArr))
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    handleSizeChange(val) {
        this.page.pageSize = val
        this.getProcedureMaterialsListPage()
      },
      // 当前页变化
      handleCurrentChange(val) {
        this.page.currentPage = val
        this.getProcedureMaterialsListPage()
      },
      // 设置tableData
    setTableData() {
      this.$refs.mTable.setTableData()
    },
    // 获取工序下拉列表
    async getProcessList(){
      const res = await api.getProcessList({receiptId:this.params.id})
      if(res){
        this.processList = res
      }
    },
        // 获取模组下拉列表
        async getModuleList(){
          let processCondition = {}
          if(this.isChooseProcess){
            processCondition = {
              procedureId:this.processId,
              procedureLocationCode:this.procedureLocationCode
            }
          }
          const res = await api.getModuleList({processFlowId:this.processFlowId,processFlowVersionId:this.processFlowVersionId,...processCondition})
          if(res){
            this.moduleList = res
          }
        },

    // 选择工序
   async selectProcess(val){
        this.isChooseProcess = val
        this.moduleList = []
        this.moduleId = []
        this.getModuleList()
      await this.getProcedureMaterialsListPage()
      if(val || this.isChooseModule){
        this.isExpand = true
      } else{
        this.isExpand = false
      }
      this.toggleRowExpansionForAll(this.dataProcess, this.isExpand)
    },
        // 递归展开收起
        toggleRowExpansionForAll(data, isExpansion) {
          data.forEach(item => {
            this.$refs.table.toggleRowExpansion(item, isExpansion)
            if (item.children) {
              this.toggleRowExpansionForAll(item.children, isExpansion)
            }
          })
        },
        // 选择模组
        async selectModule(val){
          this.isChooseModule = val.length
          await this.getProcedureMaterialsListPage()
          if(this.isChooseModule || this.isChooseProcess){
            this.isExpand = true
          } else{
            this.isExpand = false
          }
          this.toggleRowExpansionForAll(this.dataProcess, this.isExpand)
          if(this.isChooseModule){

            this.dataProcess.forEach((item, index) => {
              let expandTable = "expandTableRef" + index
              item.children.forEach((items, indexs) => {
            this.$nextTick(()=>{
              if(!items.selectable){
              this.$refs[expandTable].toggleRowSelection(items, true)
              }
          })
          })
        })
          }else{
            this.processSelectionDatas = []
          }
        },
    // 更具id获取工艺详情
    async getProcessDetailById(id,type) {
      const res = await api.getMaterialsRequireDetail({
        receiptId:id,
        receiptType:type })
      if (res) {
        this.baseFormData = res
        this.processFlowVersionId = res.processFlowVersionId
        this.processFlowId = res.processFlowId
      }
    },
   async getProcedureMaterialsListPage(){
    const searchCondition = {
      receiptId:this.params.id,
      receiptType:this.params.type,
      search: this.functionProcessHeader.searchVal
  }
  let processCondition = {}
  let moduleCondition = {}
  if (this.isChooseProcess) {
    processCondition = {
      procedureId:this.processId,
      procedureLocationCode:this.procedureLocationCode
    }
  }
  if (this.isChooseModule) {
    moduleCondition = {
      moduleMaterialsIds:this.moduleId.join(',')
    }
  }

  this.functionProcessHeader.exportParams = searchCondition
      const res = await api.getProcedureMaterialsListPage(
        {
         page: this.page.currentPage,
         size:this.page.pageSize,
         ...searchCondition,
         ...processCondition,
         ...moduleCondition
      })
      if (res) {
        this.dataProcess = res.records.map((item) => {
          return {
            ...item,
            children:item.children.map((items) => {
              return {
                ...items,
                selectable: items.notReceiveQuantity - items.appliedUnissuedQuantity > 0 ? false:true
              }
            })
          }
        })
        this.page.total = res.total
      }
    },
    async getWorkTableData(condition, callback) {
        const { page, search, order } = condition
        const searchCondition = {
            receiptId:this.params.id,
            receiptType:this.params.type,
          search: this.functionHeader.searchVal
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getWorkOrderListPage({
          page: page.currentPage,
          size: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
    // 获取基本信息值
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && !['materialsFullName', 'areas'].includes(row.prop)) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    selectionChange(data) {
        this.selectionDatas = data
        this.occupyList = data
    },
    handleSelectionChange(index,data) {
      let arr = []
      this.originProcessSelectionDatas[data] = index
        this.processSelectionDatas = this.originProcessSelectionDatas.flat();
    },
    selectable(row) {
      if (row.selectable) {
        return false
      }
      return true
    },
    // 批量锁库
    batchOccupy(){
        this.occupyList = this.selectionDatas.map(item=>{
            return{
                ...item,
                lockQuantity:item.lockedQuantity,
                maxLockQuantity:item.notReceiveQuantity > item.availableStockQuantity ? item.availableStockQuantity:item.notReceiveQuantity
            }
        })
        this.visible = true
    },
    occupy(row){
     this.occupyList = [row].map(item=>{
      return{
          ...item,
          lockQuantity:item.lockedQuantity,
          maxLockQuantity:item.notReceiveQuantity > item.availableStockQuantity ? item.availableStockQuantity:item.notReceiveQuantity
      }
  })
      this.visible = true
    },
    async cancelOccupy(row){
        this.$confirm('您确定要取消锁库吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async() => {
            let obj = {
              receiptId:this.params.id,
              receiptType:this.params.type,
              materialsId:row.materialsId
            }
            const res = await api.cancelLockedQuantity(obj)
            if (res) {
              this.$message.success('取消锁库成功')
              this.$refs.mTable.setTableData(true)
            }
          })
    },
    // 保存
    async submitForm(callback) {
      let obj = {
        receiptId:this.params.id,
        receiptType:this.params.type,
        lockedList:this.occupyList.map(item=>{
          return{
              materialsId:item.materialsId,
              lockedQuantity:item.lockQuantity
          }
      })
      }
          const res = await api.lockedQuantity(
           obj
          )
          if (res) {
            this.$message.success('锁库成功')
            this.visible = false
            this.$refs.mTable.setTableData(true)
            callback(true)
          }
    },
   back() {
    this.$router.back()
    }
  }
}
