<template>
    <div class="role-wrap">
      <el-radio-group v-model="nowRadio" class="m-radio-group" @change="radioChange">
        <el-radio-button v-for="item in handleStatusOptions" :label="item.id">{{item.name}}（{{item.number ? item.number : 0}}）</el-radio-button>
      </el-radio-group>
      <div class="orders">
        <div class="top">
          <SearchForm
            ref="searchForm"
            :expand-limit="7"
            :form-list-extend="searchFormList"
            :form-data="searchFormData"
            style="padding: 0"
            @update:formData="data=>searchFormData=data"
            @search="reSearch"
          />

        </div>
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="请输入异常单号/异常现象"
          :tags="!selectData.length ? functionHeader.tags :[]"
          style="padding: 10px 0 "
          class="func-header"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          @search="$refs.mTable.setTableData(true)"
        >
          <div slot="all" v-show="selectData.length">
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
          </div>
         </FunctionHeader>
        <MTable
          ref="mTable"
          only-key="id"
          class="table-left"
          :columns="columns"
          :height="height"
          :set-data-method="getTableData"
          @selection-change="data=>selectData = data"
          :columns-key="columnsKey"
        >
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <NewPageOpen slot="abnormalOrderCode" slot-scope="{ row }" :path="{ path: '/call/exception-handle/detail', query: { id: row.id }}" :text="row.abnormalOrderCode" />
          <div slot="status" slot-scope="{row }">{{ handleStatusMap[row.status] }}</div>
          <div slot="emergencyDegree" slot-scope="{row }">{{ emergencyDegreeMap[row.emergencyDegree] }}</div>
          <div slot="majorType" slot-scope="{row }">{{ row.majorType|majorTypeOptions }}</div>
          <div slot="currentProcessUser" slot-scope="{row }">
            <el-tooltip effect="dark" :content="row.currentProcessUser"  placement="top-end">
              <span class="mr-10">
                {{ row.currentProcessUser }}
             </span>
            </el-tooltip>
          </div>
          <div slot="judgeResult" slot-scope="{row }">{{ judgeResultMap[row.judgeResult] }}</div>
          <div slot="finalResult" slot-scope="{row }">{{ finalResultMap[row.finalResult] }}</div>
          <div slot="action" slot-scope="{row }">
            <template>
              <el-button v-if="permission('Detail')" type="text" @click="onDetail(row.id)">详情</el-button>
            </template>
          </div>
        </MTable>

      </div>

    </div>
  </template>
  <script>
  // import SelectRemoteSearch from '@/components/SelectRemoteSearch/index.vue'
  import api from '@/api/call/search'
  import userApi from '@/api/user'
  import FunctionHeader from '@/components/FunctionHeader'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import { columns, searchFormList, emergencyDegreeMap, searchFormMap, handleStatusMap, judgeResultMap, finalResultMap } from './data'
  import { majorTypeOptions} from '@/config/options.config'
  import dayjs from 'dayjs'
  import {handleStatusOptions} from './data'

  export default {
    name: 'ExceptionList',
    components: { NewPageOpen, FunctionHeader, SearchForm },
    filters: {
      majorTypeOptions(val) {
        if (val == 0 || val) return majorTypeOptions.find((item) => item.id === val).name
      }
    },
    props:{
      projectId: {
      type: Number,
      default: undefined
    }
    },
    data() {
      return {
        //1待判定   2待处理   5已处理   6已关闭
        handleStatusOptions,
        nowRadio: 1,
        params:{
          radio:'ExceptionHandle'
        },
        emergencyDegreeMap,
        handleStatusMap,
        judgeResultMap,
        finalResultMap,
        api,
        showTable: false,
        searchFormList,
        searchFormData: {},
        functionHeader: {
          searchVal: '',
          tags: [
            {
              hidden: !this.permission('Export'),
              tagName: 'el-button',
              props: { type: 'primary', size: 'small' },
              style: { width: 'auto', background: '#607FFF',marginRight:'10px'},
              innerText: '全部导出',
              on: {
                click: () => this.batchExport(true)
              }
            }
          ],
           exportName: '',
           exportParams: {}
        },
        columns,
        selectData: [],
        total:0
      }
    },
    computed: {
      height() {
        return this.projectId ? this.$store.state.app.contentHeight - 220 - 85 - 42: this.$store.state.app.contentHeight - 220 - 85
      },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    }
    },
    created() {
      this.getUser()
      this.getExceptionTypeList()
      this.getDutyPowerList()
      this.getListNumber()
    },

    methods: {
      radioChange(){
        this.searchFormData.status = this.nowRadio
        this.$refs.mTable.setTableData(true)
      },
      async getListNumber(){
        const res = await api.getOrderNum()
        if (res) {
          consoleSelf.info("数量", res)
          this.handleStatusOptions.forEach(item => {
            item.number = res[item.id] || 0
          })
        }
      },
      resetSelectData() {
        this.selectData.forEach(item => {
          if (!item.selectable) {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
          }
        })
        this.selectData = this.selectData.filter((item) => item.selectable)
    },
      async getUser() {
        const res = await userApi.getUsers()
        if (res) {
          searchFormMap.initiatorId[0].options = res
        }
      },
      async getExceptionTypeList() {
        const res = await api.exceptionTypeList()
        if (res) {
          searchFormMap.abnormalTypeId[0].options = res
        }
      },
      async getDutyPowerList() {
        const res = await api.dutyPowerList()
        if (res) {
          if (res[0].id === 0) res.splice(0, 1) // 去掉供应商
          searchFormMap.initiatorOrganizationId[0].options = res
        }
      },
      onDetail(id) {
        this.$router.push({ path: '/call/exception-handle/detail', query: { id }})
      },
      onHandle(type, row) {
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          // const res = await api.deleteOrder({ id: row.id })
          // if (res) {
          //   this.$message.success('删除成功')
          //   this.$refs.mTable.setTableData()
          // }
        })
      },
      async onUrge(row){
        const res = await api.onUrge({ id: row })
          if (res) {
            this.$message.success('催办成功')
            this.$refs.mTable.setTableData()
          }
      },
      reSearch(data, val) {
        if (val === 1) {
          this.searchFormData = {}
        }
        this.$refs.mTable.setTableData(true)
      },
      initPage() {
        this.$refs.mTable.tableRefs().clearSelection()
        this.$refs.mTable.setTableData(true)
      },
      async batchExport(isAll) {
          const params = isAll ? this.functionHeader.exportParams : { abnormalOrderIds: this.selectData.map(item => item.id) }
          const res = await api.exportExceptionList(params)
          if (res && res.data.byteLength) {
            const urlP = window.URL.createObjectURL(
              new Blob([res.data], {
                type: 'application/octet-stream;charset=ISO8859-1'
              })
            )
            const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
            this.downloadFile(urlP, fileName)
          } else {
            this.$message.error('导出失败，内容为空')
          }
        },
        allMore(command) {
            const functions = {
              batchExport: this.batchExport
            }
            functions[command]()
          },
        downloadFile(urlP, name) {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = urlP
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        },

      // 列表
      async getTableData(condition, callback) {
        const { page } = condition
        const { occurrenceTime, ...data } = this.searchFormData
        const params = {
          search: this.functionHeader.searchVal,
          occurrenceTimeStart: occurrenceTime ? occurrenceTime[0] : null,
          occurrenceTimeEnd: occurrenceTime ? occurrenceTime[1] : null,
          ...data
        }
        const searchCondition = {
          search: this.functionHeader.searchVal,
          ...data
        }
        this.functionHeader.exportParams = searchCondition
        console.log(this.projectId)
        const res = await api.list({
          page: page.currentPage,
          size: page.pageSize,
          projectId:this.projectId,
          ...params
        })
        if (res) {
          this.showTable = res.total
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .orders {
    padding: 10px;
  }
  ::v-deep .custom-sel {
    div {
      min-width: 100%;
    }
  }

  div.tooltip {
    display: inline-block;
    padding: 3px 5px;
    background: #eceef2;
    position: relative;
    border-radius: 3px;
    font-size: 12px;
    color: #393d60;
    margin-left: 10px;
  }

  div.tooltip:before,
  div.tooltip:after {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    border: 3px solid transparent;
  }
  div.tooltip:before {
    border-right-color: #eceef2;
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  div.tooltip:after {
    border-right-color: #eceef2;
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  </style>
