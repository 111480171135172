import { status } from '@/config/options.config'
import { columns, packageColumns, unitColumns } from './columns'
import { transformColumnsToForm } from '@/utils'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
const inventoryFormList = [
  {
    tagName: 'div',
    colSpan: 24,
    children: [{
      customSlots: ['unit']
    }]
  },
  // {
  //   label: '库存预警',
  //   colSpan: 24,
  //   tagName: 'labelTitle'
  // },
  // {
  //   label: '库存上限',
  //   key: 'upperLimit',
  //   colSpan: 8,
  //   tagName: 'el-input-number',
  //   props: {
  //     controlsPosition: 'right'
  //   },
  //   children: []
  // },
  // {
  //   label: '库存下限',
  //   key: 'lowerLimit',
  //   colSpan: 8,
  //   tagName: 'el-input-number',
  //   props: {
  //     controlsPosition: 'right'
  //   },
  //   children: []
  // },
  // {
  //   label: '默认仓库',
  //   key: 'defaultRepositoryId',
  //   colSpan: 8,
  //   tagName: 'el-select',
  //   children: [{
  //     tagName: 'el-option',
  //     props: {
  //       label: 'name',
  //       value: 'id'
  //     },
  //     options: []
  //   }]
  // },
  {
    tagName: 'labelTitle',
    label: '物料属性',
    colSpan: 24
  },
  {
    label: '批次码管理',
    key: 'isBatchManager',
    colSpan: 24,
    tagName: 'el-radio-group',
    required: true,
    defaultValue: 1,
    children: [{
      tagName: 'el-radio',
      innerValue: 'name',
      options: status,
      props: {
        label: 'id'
      }
    }]
  },
  {
    label: '序列号管理',
    key: 'sequenceNumber',
    colSpan: 24,
    tagName: 'el-radio-group',
    required: true,
    defaultValue: 0,
    colAttrs: { classDom: 'bgf-sequence' },
    children: [{
      tagName: 'el-radio',
      innerValue: 'name',
      options: status,
      props: {
        label: 'id'
      }
    }]
  },
  {
    label: '重量管理',
    key: 'isWeightManager',
    tagName: 'el-radio-group',
    required: true,
    defaultValue: 0,
    on: {
      change: (val) => { functionList.isWeightManagerChange(val) }
    },
    children: [{
      tagName: 'el-radio',
      innerValue: 'name',
      options: status,
      props: {
        label: 'id'
      }
    }]
  },
  {
    // hidden: true,
    tagName: 'div',
    label: '重量关系',
    required: false,
    key: 'weightUnit',
    children: [{
      customSlots: ['weightRelative']
    }]
  },
  {
    label: '材质管理',
    key: 'isQualityManager',
    tagName: 'el-radio-group',
    required: true,
    defaultValue: 0,
    on: {
      change: (val) => { functionList.isQualityManagerChange(val) }
    },
    children: [{
      tagName: 'el-radio',
      innerValue: 'name',
      options: status,
      props: {
        label: 'id'
      }
    }]
  },
  {
    label: '材质',
    key: 'materialsQualityId',
    tagName: 'el-select',
    required: false,
    props: {
      disabled: true
    },
    children: [{
      tagName: 'el-option',
      props: {
        label: 'name',
        value: 'id'
      },
      options: []
    }]
  },
  {
    tagName: 'div',
    colSpan: 24,
    children: [{
      customSlots: ['package']
    }]
  }
]
const wrapByRowFormItem = (data) => {
  const newData = []
  data.forEach(item => {
    newData.push({
      tagName: 'el-col',
      style: { width: '' },
      props: {
        span: item.colSpan || 12
      },
      children: [item.tagName === 'labelTitle' ? {
        tagName: 'div',
        domProps: { className: 'label-title' },
        innerText: item.label,
        children: []
      } : {
        tagName: 'el-form-item',
        props: {
          label: item.label,
          prop: item.key
        },
        children: [item]
      }]
    })
  })
  return [{
    tagName: 'el-row',
    props: {
      gutter: 20
    },
    children: newData
  }]
}
const formList = [
  {
    tagName: 'labelTitle',
    label: '基本信息',
    colSpan: 24
  },
  ...transformColumnsToForm(columns),
  {
    tagName: 'el-tabs',
    colSpan: 24,
    key: 'tabName',
    defaultValue: 'inventory',
    on: {
      'tab-click': (val) => { functionList.tabClick(val) }
    },
    children: [
      {
        tagName: 'el-tab-pane',
        props: {
          label: '基础配置',
          name: 'inventory'
        },
        children: wrapByRowFormItem(inventoryFormList)
      },
      {
        hidden: true,
        tagName: 'el-tab-pane',
        props: {
          label: '工艺路线',
          name: 'process'
        },
        children: [{
          customSlots: ['process']
        }]
      },
      {
        hidden: true,
        tagName: 'el-tab-pane',
        props: {
          label: '图纸及附件',
          name: 'drawAndFile'
        },
        children: [{
          customSlots: ['drawAndFile']
        }]
      }
    ]
  }
]
formList[formList.length - 1].children.push({
  hidden: true,
  tagName: 'el-tab-pane',
  props: {
    label: '物料BOM',
    name: 'bom'
  },
  children: [{
    customSlots: ['bom']
  }]
})
export { formList }

export const searchFormList = [
  {
    key: 'isEnabled',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择物料状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: status
      }
    ]
  },
  {
    colSpan: 6,
    key: 'materialsTypeId',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '请选择物料类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'materialsAttributeId',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '请选择物料属性'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'specifications',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入物料规格'
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'code',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入物料编码'
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'name',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入物料名称'
    },
    children: []
  }
]

const unitFormListFromColumn = transformColumnsToForm(unitColumns)
unitFormListFromColumn.splice(1, 0, {
  tagName: 'div',
  key: 'conversionFactor',
  label: '换算关系',
  colSpan: 18,
  required: true,
  children: [{
    customSlots: ['unitConversion']
  }]
})
export const unitFormList = unitFormListFromColumn
const packageFormListFromColumn = transformColumnsToForm(packageColumns)
packageFormListFromColumn.splice(3, 0, {
  tagName: 'div',
  label: '换算系数',
  key: 'conversionFactor',
  required: true,
  colSpan: 18,
  children: [{
    customSlots: ['unitConversion']
  }]
})
export const packageFormList = packageFormListFromColumn

// export { getFunctionList }
