export const columnsAll = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      sortable: true,
      hiddenSearch: true,
      minWidth: 80
    },
    {
      prop: 'materialsTypeName',
      label: '物料类型',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'materialsCode',
      label: '物料编号',
      sortable: false,
      hiddenSearch: true,
      minWidth: 180
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true,
      minWidth: 180
    },
    {
        prop: 'specifications',
        label: '物料规格',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
    {
      prop: 'mainUnitName',
      label: '主单位',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'requirementQuantity',
      label: '需求数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
        prop: '',
        label: '',
        sortable: false,
        hiddenSearch: true,
        headerSlotName: 'headerSlot',
        slotName:'viewDetail',
        minWidth: 120
      },
    {
        prop: 'missMaterialsQuantity',
        label: '缺料数量',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      {
        prop: 'missMaterialsRate',
        label: '缺料率',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120,
        slotName:'missMaterialsRate'
      },
      // {
      //   prop: 'postponeQuantity',
      //   label: '延期数量',
      //   sortable: false,
      //   hiddenSearch: true,
      //   minWidth: 120
      // },
      {
        prop: 'supplyQuantity',
        label: '供应数量',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      {
        prop: 'stockQuantity',
        label: '库存数量',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      // {
      //   prop: 'onPassageQuantity',
      //   label: '在途数量',
      //   sortable: false,
      //   hiddenSearch: true,
      //   minWidth: 120
      // },
      // {
      //   prop: 'purchaseQuantity',
      //   label: '请购数量',
      //   sortable: false,
      //   hiddenSearch: true,
      //   minWidth: 120
      // },
      // {
      //   prop: 'makingQuantity',
      //   label: '在制数量',
      //   sortable: false,
      //   hiddenSearch: true,
      //   minWidth: 120
      // }
]
export const columnsLeft = [
      {
        prop: 'index',
        label: '序号',
        slotName: 'index',
        sortable: true,
        hiddenSearch: true,
        minWidth: 80
      },
      {
        prop: 'materialsTypeName',
        label: '物料类型',
        sortable: false,
        hiddenSearch: true,
        minWidth: 150
      },
      {
        prop: 'name',
        label: '物料编号',
        sortable: false,
        hiddenSearch: true,
        minWidth: 180
      },
      {
        prop: 'version',
        label: '物料名称',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      {
        prop: 'unit',
        label: '主单位',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      {
        prop: 'version',
        label: '需求数量',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      }
]
export const columnsRight = [
    {
        prop: 'index',
        label: '序号',
        slotName: 'index',
        sortable: true,
        hiddenSearch: true,
        minWidth: 80
      },
      {
        prop: 'materialsTypeName',
        label: '物料类型',
        sortable: false,
        hiddenSearch: true,
        minWidth: 150
      },
      {
        prop: 'materialsCode',
        label: '物料编号',
        sortable: false,
        hiddenSearch: true,
        minWidth: 180
      },
      {
        prop: 'materialsName',
        label: '物料名称',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      {
          prop: 'specifications',
          label: '物料规格',
          sortable: false,
          hiddenSearch: true,
          minWidth: 120
        },
      {
        prop: 'mainUnitName',
        label: '主单位',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
    {
      prop: 'supplyQuantity',
      label: '供应数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
        prop: 'missMaterialsQuantity',
        label: '缺料数量',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      // {
      //   prop: 'postponeQuantity',
      //   label: '延期数量',
      //   sortable: false,
      //   hiddenSearch: true,
      //   minWidth: 120
      // }
]
// 需求详情
export const columnsRequirement = [
    {
        prop: 'level',
        label: '层级',
        sortable: false,
        hiddenSearch: true,
        minWidth: 80
      },
      {
        prop: 'code',
        label: '编号',
        sortable: false,
        hiddenSearch: true,
        minWidth: 150
      },
      {
        prop: 'name',
        label: '名称',
        sortable: false,
        hiddenSearch: true,
        minWidth: 150
      },
      {
        prop: 'quantity',
        label: '数量',
        sortable: false,
        hiddenSearch: true,
        minWidth: 150
      },
      {
        prop: 'requireDate',
        label: '需求日期',
        sortable: false,
        hiddenSearch: true,
        minWidth: 150
      }
]
// 供应详情
export const columnsSupply = [
    {
        prop: 'level',
        label: '层级',
        sortable: false,
        hiddenSearch: true,
        width:100
      },
      {
        prop: 'code',
        label: '编号',
        sortable: false,
        hiddenSearch: true,
        minWidth: 150
      },
      {
        prop: 'name',
        label: '名称',
        sortable: false,
        hiddenSearch: true,
        minWidth: 150
      },
      {
        prop: 'quantity',
        label: '数量',
        sortable: false,
        hiddenSearch: true,
        minWidth: 100
      },
      {
        prop: 'requireDate',
        label: '日期',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      {
        prop: 'associatedOrderNumber',
        label: '关联单号',
        sortable: false,
        hiddenSearch: true,
        minWidth: 150
      },
      {
        prop: 'associatedName',
        label: '关联名称',
        sortable: false,
        hiddenSearch: true,
        minWidth: 150
      }
]
