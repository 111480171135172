import { transformColumnsToForm } from '@/utils'
import { columns } from './columns'
import _ from 'lodash'
const newColumns = _.cloneDeep(columns)
newColumns.forEach(item => {
  if (item.form) {
    item.form.colSpan = 6
  }
})
newColumns.splice(8, 1, {
  prop: 'picture',
  label: '物料图片',
  sortable: false,
  hiddenSearch: true,
  form: {
    index: 9,
    colSpan: 6,
    tagName: 'div',
    customSlots: ['picture'],
    required: false
  }
})
export const formList = transformColumnsToForm(newColumns)
