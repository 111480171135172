var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderAdd" }, [
    _c(
      "div",
      { staticClass: "detail-card", staticStyle: { padding: "10px" } },
      [
        _c(
          "div",
          { staticClass: "m-table" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                ref: "table",
                attrs: {
                  data: _vm.dataProcess,
                  "show-header": true,
                  "default-expand-all": _vm.isExpand
                }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-table",
                            {
                              ref: "expandFirstTableRef" + props.$index,
                              staticClass: "ellipsis-table",
                              attrs: { data: props.row.children },
                              on: { "expand-change": _vm.onExpand }
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "expand" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(nestedScope) {
                                        return [
                                          _c(
                                            "el-table",
                                            {
                                              ref:
                                                "expandTable" +
                                                nestedScope.row
                                                  .procedureLocationCode,
                                              staticClass: "ellipsis-table",
                                              attrs: {
                                                data: nestedScope.row.children,
                                                maxHeight: _vm.tableHeight,
                                                "row-key": "id"
                                              },
                                              on: {
                                                "selection-change": function(
                                                  data
                                                ) {
                                                  return _vm.handleSelectionChange(
                                                    data,
                                                    nestedScope.$index,
                                                    props.$index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "selection",
                                                  "reserve-selection": true,
                                                  selectable: _vm.selectable
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "index",
                                                  label: "序号"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                scope.$index + 1
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "materialsType",
                                                  label: "物料类型"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                scope.row
                                                                  .materialsType
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "materialsCode",
                                                  label: "物料编号"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "materialsName",
                                                  label: "物料名称",
                                                  width: "200"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "specifications",
                                                  label: "物料规格"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "isKeyMaterials",
                                                  label: "是否关键物料"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                scope.row
                                                                  .isKeyMaterials
                                                                  ? "是"
                                                                  : "否"
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "mainUnitName",
                                                  label: "主单位"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "requirementQuantity",
                                                  label: "需求数量"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "receivedQuantity",
                                                  label: "已领数量"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "applyQuantity",
                                                  label: "申请数量",
                                                  width: "130"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _c(
                                                            "el-input-number",
                                                            {
                                                              staticStyle: {
                                                                width: "90%"
                                                              },
                                                              attrs: {
                                                                min: 0,
                                                                "controls-position":
                                                                  "right",
                                                                step: 1
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .applyQuantity,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    "applyQuantity",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "scope.row.applyQuantity"
                                                              }
                                                            }
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "notReceiveQuantity",
                                                  label: "未领数量"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop:
                                                    "appliedUnissuedQuantity",
                                                  label: "申请未发数量"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "requireDate",
                                                  label: "需求日期"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "procedureName",
                                  label: "工序名称"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "procedureLocationCode",
                                  label: "工序定位码"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "requirementQuantity",
                                  label: "需求数量"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "receivedQuantity",
                                  label: "已领数量"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "notReceiveQuantity",
                                  label: "未领数量"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "keyMaterialsRequirementQuantity",
                                  label: "关键料数量"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "procedureCompleteRate",
                                  label: "工序齐套率"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.procedureCompleteRate
                                              ) +
                                              "% "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "notReceiveCompleteRate",
                                  label: "未领齐套率"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.notReceiveCompleteRate
                                              ) +
                                              "% "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "keyMaterialsCompleteRate",
                                  label: "关键料齐套率"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row
                                                  .keyMaterialsCompleteRate
                                              ) +
                                              "% "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "index", label: "序号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "receiptNumber", label: "单据编号" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "receiptName", label: "单据名称" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "receiptType", label: "单据类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("receiptType")(scope.row.receiptType)
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "receiptStatus", label: "单据状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("receiptStatus")(scope.row.receiptStatus)
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "requirementQuantity", label: "需求总量" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "receivedQuantity", label: "已领数量" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "notReceiveQuantity", label: "未领数量" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "requireDate", label: "需求时间" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "projectNumber", label: "项目编号" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "projectName", label: "项目名称" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "remark", label: "备注" }
                })
              ],
              1
            )
          ],
          1
        )
      ]
    ),
    _c("div", { staticClass: "order-footer" }, [
      _c("div"),
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c("el-button", { on: { click: _vm.returnList } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "lines" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }