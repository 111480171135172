export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    minWidth: 80,
    hiddenSearch: true,
    slotName: 'index'
  },
  {
    prop: 'receiptNumber',
    label: '单据编码',
    slotName: 'receiptNumber',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'receiptName',
    label: '单据名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'receiptType',
    label: '单据类型',
    sortable: false,
    slotName: 'receiptType',
    minWidth: 120,
    hiddenSearch: true
  },

  {
    prop: 'receiptStatus',
    label: '单据状态',
    sortable: false,
    slotName: 'receiptStatus',
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'requirementQuantity',
    label: '需求总量',
    sortable: false,
    hiddenSearch: true,
    minWidth: 100
  },
  {
    prop: 'receivedQuantity',
    label: '已领数量',
    sortable: false,
    hiddenSearch: true,
    minWidth: 100
  },
  {
    prop: 'notReceiveQuantity',
    label: '未领数量',
    sortable: false,
    hiddenSearch: true,
    minWidth: 100
  },
  // {
  //   prop: 'lockedQuantity',
  //   label: '锁库数量',
  //   sortable: false,
  //   hiddenSearch: true,
  //   minWidth: 100
  // },
  {
    prop: 'requireDate',
    label: '需求时间',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'projectNumber',
    label: '项目编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'projectName',
    label: '项目名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
