var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "process-detail-wrapper" },
    [
      _c("div", { staticClass: "process-detail-header flex-sbc" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.back()
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "right flex-sbc" },
          [
            _vm.saveButton
              ? _c("el-button", { on: { click: _vm.checkData } }, [
                  _vm._v("工艺连续性检查")
                ])
              : _vm._e(),
            _vm.saveButton || _vm.permission("CopyVersion")
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.buttonLoading },
                    on: {
                      click: function($event) {
                        return _vm.modifyOrCopyVersion("copyVersion")
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.saveButton ? "保存" : "复制此版本") + " "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "process-detail-body flex-sb" }, [
        !_vm.saveButton
          ? _c("div", { staticClass: "left" }, [
              _c("div", { staticStyle: { border: "1px solid #DDE3EB" } }, [
                _vm._m(0),
                _c(
                  "ul",
                  { staticClass: "version-wrapper" },
                  _vm._l(_vm.versionList, function(item) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        class: _vm.currentVersionId === item.id ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.viewVersion(item)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "li-left" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                attrs: { title: item.version }
                              },
                              [
                                _c("div", { staticClass: "ellipsis" }, [
                                  _vm._v(_vm._s(item.version))
                                ]),
                                item.isMain
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "54px",
                                        "margin-left": "10px"
                                      },
                                      attrs: {
                                        src: require("../../../../../assets/information/process/主版本.png")
                                      }
                                    })
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "info ellipsis",
                                attrs: {
                                  title:
                                    "「 " +
                                    item.creator +
                                    " 」上传于" +
                                    item.createTime
                                }
                              },
                              [
                                _vm._v(
                                  " 「 " +
                                    _vm._s(item.creator) +
                                    " 」上传于" +
                                    _vm._s(item.createTime) +
                                    " "
                                )
                              ]
                            ),
                            _vm.currentVersionId === item.id
                              ? _c(
                                  "el-dropdown",
                                  {
                                    staticClass: "dropdown",
                                    on: { command: _vm.versionMore }
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "el-icon-more-btn",
                                      attrs: { icon: "el-icon-more" }
                                    }),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        staticClass: "td-dropdown-menu",
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      [
                                        _vm.permission("ReNameVersion")
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: "replaceName"
                                                }
                                              },
                                              [_vm._v("重命名")]
                                            )
                                          : _vm._e(),
                                        _vm.permission("EditVersion")
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: "modifyVersion"
                                                }
                                              },
                                              [_vm._v("编辑")]
                                            )
                                          : _vm._e(),
                                        _vm.permission("SetMainVersion") &&
                                        !item.isMain
                                          ? _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "setMain" } },
                                              [_vm._v("设为主版本")]
                                            )
                                          : _vm._e(),
                                        _vm.permission("DelVersion") &&
                                        !item.isMain
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: { command: "delVersion" }
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            { staticClass: "detail-card" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "detail-tabs",
                  attrs: { type: "border-card" },
                  model: {
                    value: _vm.baseInfoTabName,
                    callback: function($$v) {
                      _vm.baseInfoTabName = $$v
                    },
                    expression: "baseInfoTabName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "基本信息", name: "基本信息" } },
                    [
                      _c(
                        "div",
                        { staticClass: "card-bot" },
                        [
                          _vm.saveButton &&
                          ["add", "copy"].indexOf(_vm.params.type) !== -1
                            ? _c(
                                "MFormBuilder",
                                {
                                  ref: "baseForm",
                                  attrs: {
                                    "form-list": _vm.baseFormList,
                                    "form-data": _vm.baseFormData
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "processFlowClassName" },
                                      slot: "processFlowClassName"
                                    },
                                    [
                                      _c("CURDSelect", {
                                        attrs: {
                                          "default-value":
                                            _vm.baseFormData.processFlowClassId,
                                          "default-value-index": 0,
                                          "api-name": "ProcessType"
                                        },
                                        on: { change: _vm.selectName }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : [
                                _c("MFormBuilder", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false"
                                    }
                                  ],
                                  ref: "baseForm",
                                  attrs: {
                                    "form-list": _vm.baseFormList,
                                    "form-data": _vm.baseFormData
                                  }
                                }),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "view-list",
                                    attrs: { gutter: 24 }
                                  },
                                  _vm._l(_vm.columns.base, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "el-col",
                                      {
                                        key: item.prop,
                                        style: {
                                          marginBottom:
                                            index !== 6 ? "20px" : 0,
                                          display: "inline-flex"
                                        },
                                        attrs: {
                                          title: _vm.getValue(item),
                                          span: 8
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "word-break": "keep-all"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.label) + "：")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "word-break": "break-all"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.getValue(item)))]
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ]
                        ],
                        2
                      )
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "工艺文件", name: "工艺文件" } },
                    [
                      _c("ProcessFile", {
                        ref: "ProcessFile",
                        attrs: {
                          "base-list": _vm.baseFormData.processFiles,
                          "read-only":
                            !_vm.saveButton ||
                            !["add", "copy"].includes(_vm.params.type)
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "detail-card" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "detail-tabs",
                  attrs: { type: "border-card" },
                  on: { "tab-click": _vm.switchTab },
                  model: {
                    value: _vm.activeTabName,
                    callback: function($$v) {
                      _vm.activeTabName = $$v
                    },
                    expression: "activeTabName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "工艺路线图", name: "工艺路线图" } },
                    [
                      _c(
                        "div",
                        { staticClass: "card-bot process-card" },
                        [
                          _c("process-flow", {
                            ref: "processFlow",
                            attrs: {
                              "base-form": _vm.$refs.baseForm,
                              "read-only": !_vm.saveButton,
                              "process-flow-detail": _vm.processFlowVersion,
                              "handle-type": _vm.params.type,
                              "can-import": _vm.baseFormData.isEnabled,
                              "enable-step": _vm.enableStep,
                              "add-type": _vm.addType
                            },
                            on: {
                              addDetail: _vm.addDetails,
                              hideLoading: function($event) {
                                _vm.buttonLoading = false
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: "BOM",
                        name: "BOM",
                        disabled: ["add", "copy"].includes(_vm.params.type)
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" BOM "),
                          ["add", "copy"].includes(_vm.params.type)
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "工艺路线保存后才能查看",
                                    placement: "top-start"
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-question" })]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("BOM", {
                        ref: "BOM",
                        attrs: {
                          "process-flow-version": _vm.processFlowVersion,
                          "base-form-data": _vm.baseFormData
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "质检项目", name: "质检项目" } },
                    [
                      _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v(" 质检项目 ")
                      ]),
                      _c("inspection-project", {
                        ref: "inspectionProject",
                        attrs: {
                          "handle-type": _vm.params.type,
                          "process-flow-id": parseInt(_vm.params.id),
                          "process-flow-version": _vm.processFlowVersion
                        },
                        on: { searchInspectTable: _vm.searchInspectTable }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "MDialog",
        {
          attrs: { width: "400px", title: "提示" },
          on: { onOk: _vm.submitVersionForm },
          model: {
            value: _vm.versionVisible,
            callback: function($$v) {
              _vm.versionVisible = $$v
            },
            expression: "versionVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "versionForm",
            attrs: {
              "label-position": "left",
              "form-list": _vm.versionFormList,
              "form-data": _vm.versionFormData
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "history-version",
        staticStyle: { "padding-right": "10px" }
      },
      [
        _c("img", {
          staticStyle: { width: "20px", "margin-right": "5px" },
          attrs: {
            src: require("../../../../../assets/information/process/历史版本@2x.png")
          }
        }),
        _c(
          "div",
          {
            staticStyle: {
              position: "relative",
              top: "2px",
              "word-break": "keep-all"
            }
          },
          [_vm._v("历史版本")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }